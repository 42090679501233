import React from "react";
import FeatherIcon from "feather-icons-react";

function Offers(){
return(
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
            <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
            What does Testenium offer?
            </div>
            <ul className="text-sm sm:text-base  text-justify ">
                <li className="flex mb-1 ">
                    <div>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2 "
                /></div>
                    <div> Full-Code automation - creates projects, adds dependencies and generates 100% test automation scripts for given requirements.</div></li>
                <li className="flex mb-1 ">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div>
                <div> Provides very comprehensive testing features - UI Testing, API Testing, LOAD Testing, Security Testing and code coverage.</div></li>
                <li className="flex mb-1">
                <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div>
                <div> Generates code for Selenium, BDD, Cypress and Microsoft Playwright in C#, Java, JavaScript, TypeScript and Python languages.</div></li>
                <li className="flex mb-1">
                <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div> Produces Video, Screenshots and PDF reports automatically.</div></li>
                <li className="flex mb-1">
                <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Supports Accelerated Massive Parallel Testing (AMPT) to run any number of projects in parallel.</div></li>
                <li className="flex mb-1">
                <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Provides a Page Object Builder (POB) to enter test automation requirements on the UI to generate code for Selenium Page Objects.</div></li>
                <li className="flex mb-1">
                <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Supports Voice-to-Code feature to dictate the feature file for BDD.</div></li>
                <li className="flex mb-1">
                <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Implements methods for BDD completely.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Allows users to implement framework and to integrate with the test code.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Compares the screenshots for different builds of the same project.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Supports version control and test management.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Supports continuous testing DevOps integration via pipeline configuration.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Supports command line access to push Feature file for BDD or Excel to provide elements' details.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Handles Authentication Code for API testing automatically.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Supports unlimited virtual users for LOAD testing.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Generates code for testing Smart Contracts and tests it on Ganache testnet on cloud.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Provides Oracle Express and SQL Server Express online.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Prevents users from deleting and renaming the files and formatting the hard disk of the server.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Encrypts the PDF test reports and keeps it securely for future use. Decrypts the report whenever the user wants to view only.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Encrypts the source code and deletes the original source code immediately after completing the test to secure the code.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Decrypts the code whenever the user wants to edit and run it.</div></li>
                <li className="flex mb-1">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Encrypts document and archives without key-management.</div></li>
                <li className="flex ">
                    <div>
                    <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-5 h-5 md:w-6 md:h-6 mr-2"
                /></div><div>Provides software to encrypt series of files on users' devices without key-management.</div></li>
            </ul>
        </div>
    </div>
)
}
export default Offers