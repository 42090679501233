import React from "react";
import MscSoftwareTesting from "../../components/web/degreeCourses/MscSoftwareTesting";
import Footer from "../../components/web/Footer";

function MscSoftwareTestingMetaAutomation() {
  return (
    <div>
      <div className="h-full">
        <MscSoftwareTesting />
        <Footer />
      </div>
    </div>
  );
}

export default MscSoftwareTestingMetaAutomation;
