import React from "react";
import DipCourses from "../components/web/DipCourses";
import DipHero from "../components/web/DipHero";
import Footer from "../components/web/Footer";

function DiplomaCourses() {
  return (
    <div>
      <div className="h-full">
        <DipHero />
        <DipCourses />
        <Footer />
      </div>
    </div>
  );
}

export default DiplomaCourses;
