import React from "react";
import DegreeHero from "../components/web/degreeCourses/DegreeHero";
import Footer from "../components/web/Footer";
import DegCourses from "../components/web/degreeCourses/DegCourses";
import AcademicPartners from "../components/web/AcademicPartners";

function DegreeCourses() {
  return (
    <div>
      <div className="h-full">
        <DegreeHero />
        <AcademicPartners />
        <DegCourses />
        <Footer />
      </div>
    </div>
  );
}

export default DegreeCourses;
