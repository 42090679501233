import React from "react";
import DipMetaMedicalInformaticsc from "../../components/web/diplomaCourses/DipMetaMedicalInformaticsc";
import Footer from "../../components/web/Footer";

function DipMetaMedicalInformatics() {
  return (
    <div>
      <div className="h-full">
        <DipMetaMedicalInformaticsc />
        <Footer />
      </div>
    </div>
  );
}

export default DipMetaMedicalInformatics;
