import React from "react";
import CrossBrowserTestAutomation from "../components/web/CrossBrowserTestAutomation";
import Footer from "../components/web/Footer";
import Offers from "../components/web/Offers";
import RobotFramework from "../components/web/RobotFramework";
import TestAutomationScale from "../components/web/TestAutomationScale";
import TestingHero from "../components/web/TestingHero";
import TestingServices from "../components/web/TestingServices";
import TestingTypes from "../components/web/TestingTypes";
import WhyTestAutomation from "../components/web/WhyTestAutomation";

function Testing(){
    return(
        <div>
            <div className="h-full">
                <TestingHero/>
                <TestingServices/>
                <TestAutomationScale/>
                <WhyTestAutomation/>
                <Offers/>
                <RobotFramework/>
                <CrossBrowserTestAutomation/>
                <TestingTypes/>
                <Footer/>
            </div>
        </div>
    )
}
export default Testing