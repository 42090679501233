import React from "react";
import encryptOnClick from "./../../assets/images/Encrypt-on-click.png";
import encryptOnUpload from "./../../assets/images/Encrypt-on-upload.png";
import secureApps from "./../../assets/images/Secure-apps.png";


function EncryptionServices(){
    return(
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3" >
        <div className="container">
          <div className="md:flex">
            <div className="flex-1  p-2">
              <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
                <img
                  className="hidden md:block h-20 mx-auto mb-2"
                  src={encryptOnClick}
                  alt="Web app development"
                />
                <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Encrypt files on a click
                </div>
                
              </div>
            </div>
            <div className="flex-1 p-2">
              <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
                <img
                  className="hidden md:block h-20 mx-auto mb-2"
                  src={encryptOnUpload}
                  alt="Web app development"
                />
                <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Encrypt by Simply Uploading
                </div>
                
              </div>
            </div>
            <div className="flex-1 p-2">
              <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
                <img
                  className="hidden md:block h-20 mx-auto mb-2"
                  src={secureApps}
                  alt="Web app development"
                />
                <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Secure Apps within Seconds
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default EncryptionServices;