import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Toast from "../components/Toast";
import Web from "../layouts/Web";
import Home from "../views/Home";
import Pricing from "../views/Pricing";
import AboutUs from "../views/AboutUs";
import NavBar from "../components/web/NavBar";
import Encryption from "../views/Encryption";
import Testing from "../views/Testing";
import Training from "../views/Training";
import SEPDevCert from "../views/certifications/SEPDevCert";
import AutomationAnalystCert from "../views/certifications/AutomationAnalystCert";
import DevOpsEngineerCert from "../views/certifications/DevOpsEngineerCert";
import AutomationArchitectCert from "../views/certifications/AutomationArchitectCert";
import AutomationEngineerCert from "../views/certifications/AutomationEngineerCert";
import AutomationPlatformDevCert from "../views/certifications/AutomationPlatformDevCert";
import DiplomaCourses from "../views/DiplomaCourses";
import DipMetaAutomation from "../views/diploma/DipMetaAutomation";
import DipBlockchainMetaAutomation from "../views/diploma/DipBlockchainMetaAutomation";
import DipMetaMedicalInformatics from "../views/diploma/DipMetaMedicalInformatics";
import DegreeCourses from "../views/DegreeCourses";
import MscSoftwareTestingMetaAutomation from "../views/degree/MscSoftwareTestingMetaAutomation";

function Main(props) {
  return (
    <>
      <BrowserRouter>
        <div className="h-full">
          <NavBar />
          <Switch>
            <Web>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/pricing">
                <Pricing />
              </Route>

              <Route path="/about-us">
                <AboutUs />
              </Route>
              <Route path="/encryption">
                <Encryption />
              </Route>
              <Route path="/testing">
                <Testing />
              </Route>
              <Route path="/training">
                <Training />
              </Route>
              {/* training courses */}

              <Route path="/sep-dev-cert">
                <SEPDevCert />
              </Route>
              <Route path="/automation-analyst-cert">
                <AutomationAnalystCert />
              </Route>
              <Route path="/automation-architect-cert">
                <AutomationArchitectCert />
              </Route>
              <Route path="/devops-eng-cert">
                <DevOpsEngineerCert />
              </Route>
              <Route path="/automation-eng-cert">
                <AutomationEngineerCert />
              </Route>
              <Route path="/automation-platform-dev-cert">
                <AutomationPlatformDevCert />
              </Route>

              {/* diploma courses */}
              <Route path="/diploma-courses">
                <DiplomaCourses />
              </Route>
              <Route path="/dip-meta-automation">
                <DipMetaAutomation />
              </Route>
              <Route path="/dip-blockchain-meta-automation-testing">
                <DipBlockchainMetaAutomation />
              </Route>
              <Route path="/dip-meta-medical-informatics">
                <DipMetaMedicalInformatics />
              </Route>

              {/* degree courses */}
              <Route path="/degree-courses">
                <DegreeCourses />
              </Route>
              <Route path="/msc-software-testing">
                <MscSoftwareTestingMetaAutomation />
              </Route>
              <Redirect from="/*" to="/home" />
            </Web>
          </Switch>
        </div>
      </BrowserRouter>
    </>
  );
}

export default Main;
