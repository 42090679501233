import React, { useState } from "react";
import ses from "./../../assets/images/SES.png";
import FeatherIcon from "feather-icons-react";
import EncryptionMoreModal from "../Modals/EncryptionMoreModal";

function NextGenDocEncryption(){
    const [more, setMore] = useState(false)
    const [moreOf, setMoreOf] = useState("nextGenDocEncryption")

    return(
        <>
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
          <div className="flex items-center gap-4">
            <div className="hidden md:block flex-1">
              <div className=" mx-auto max-w-md ">
                <img src={ses} alt="Web app development" />
              </div>
            </div>
            <div className="flex-1">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl pri-font text-pri-900 mb-3">
              Next Generation Document Encryption & Secure Cloud Archive
              </div>
              <div className="text-sm sm:text-base md:text-lg mb-6 max-w-5xl mx-auto">
              Testenium uses a next generation AI and meta computing based encryption system to encrypt files and sensitive documents without using the encryption keys external to the software. Testenium creates...
              </div>
              <div
                    onClick={() => {
                      setMore(true);
                      setMoreOf("nextGenDocEncryption");
                    }}
                    className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500" style={{width:"fit-content"}}
                  >
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </div>
               
            </div>
          </div>
        </div>
      </div>
        <EncryptionMoreModal
        visible={more}
        handleCancel={()=>setMore(false)}
        more={moreOf}
        />
      </>
    )
}

export default NextGenDocEncryption;