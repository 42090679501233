import React from "react";
import isobl from "./../../assets/images/isobl.png";
import topCompany from "./../../assets/images/top-company.png";
import clw from "./../../assets/images/clw-2021.png";
import testenium from "./../../assets/images/testenium.jpg";
import hybridPlatform from "./../../assets/images/hybrid-platform.png";
import testenium2 from "./../../assets/images/testenium2.jpg";
import newWorldReport from "./../../assets/images/new-world-report.png";

function Awards(){
    return(
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
            <div className="container">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6"> Awards and Recognition
                </div>
                <div className="grid md:grid-cols-2 gap-4 md:gap-6">
                    <div className="md:col-span-2 ">
                        <img className="w-screen shadow-lg" src={testenium} alt="testenium"/>
                    </div>
                    <div >
                    <img className="shadow-lg" src={clw} alt="testenium"/>
                    </div>
                    <div className=" bg-white">
                    <img className="shadow-lg" src={testenium2} alt="testenium"/>
                    </div>
                    <div >
                    <img className="h-full shadow-lg" src={hybridPlatform} alt="testenium"/>
                    
                    </div>
                    <div >
                    <img  className="w-full shadow-lg" src={isobl} alt="testenium"/>
                    </div>
                    <div >
                    
                    <img className="shadow-lg w-full" src={topCompany} alt="testenium"/>
                    </div>
                    <div >
                    <img className="h-full shadow-lg" src={newWorldReport} alt="testenium"/>
                    </div>
                </div>
                {/* <div className="flex flex-col items-center gap-10 ">
                    <div className=" max-w-2xl  overflow-hidden shadow-lg">
                    <img className="w-screen" src={isobl} /></div>
                    <div className="   max-w-2xl  overflow-hidden shadow-lg">
                    <img className="w-screen" src={topCompany} /></div>
                    <div className="  max-w-2xl overflow-hidden shadow-lg">
                    <img src={clw} /></div>
                    <div className="  max-w-2xl overflow-hidden shadow-lg">
                    <img src={testenium} /></div>
                    <div className="  max-w-2xl overflow-hidden shadow-lg">
                    <img src={hybridPlatform} /></div>
                    <div className=" max-w-2xl  bg-white overflow-hidden shadow-lg">
                    <img src={testenium2} /></div>
                    <div className="  max-w-2xl overflow-hidden shadow-lg">
                    <img src={newWorldReport} /></div>
                </div> */}
            </div>
        </div>
    )
    
}
export default Awards;