import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

function TestAutomation() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font ">
          Test Automation - Online Subscription
        </div>
        <div className="text-lg sm:text-xl md:text-2xl text-center mb-8">
          FREE for first month
        </div>
        <div className="grid md:self-auto md:grid-cols-2 lg:grid-cols-4  gap-4">
          <div className="border-2 border-pri-500 rounded-md ">
            {/* <div className="text-sm sm:text-base md:text-lg bg-sec-500 p-3 rounded-tr-md rounded-tl-md text-white">FREE for first month</div> */}
            <div className="p-3 lg:p-5">
              <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-0">
                Selenium Page Object
              </div>
              {/* <div className="text-center mb-2">
                <span className="text-4xl md:text-5xl">£49</span> /month
              </div> */}
              <div className="mt-6 mb-8 text-center">
                <Link
                  to={{
                    pathname:
                      "https://testenium.co.uk/testenium/app/contact_form.php",
                  }}
                  target="_blank"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  Contact us
                </Link>
              </div>
              <div className="flex  text-sm sm:text-base mb-1 ">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Get One Day Training</div>
              </div>
              <div className="flex  text-sm sm:text-base mb-1 ">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Create Project Automatic</div>
              </div>
              <div className="flex  text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Upload Excel File with Elements</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Generate Test Script</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Execute on Cloud</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Unlimited projects</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Unlimited Screenshots</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Video & PDF Reports</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Test Management</div>
              </div>
            </div>
          </div>
          <div className="border-2 border-pri-500  rounded-md ">
            {/* <div className="text-sm sm:text-base md:text-lg bg-sec-500 p-3 rounded-tr-md rounded-tl-md text-white">FREE for first month</div> */}
            <div className="p-3 lg:p-5">
              <div className="text-lg sm:text-xl md:text-2xl text-center pri-font">
                Voice Activated BDD
              </div>
              {/* <div className="text-center mb-2">
                <span className="text-4xl md:text-5xl">£49</span> /month
              </div> */}
              <div className="mt-6 mb-8 text-center">
                <Link
                  to={{
                    pathname:
                      "https://testenium.co.uk/testenium/app/contact_form.php",
                  }}
                  target="_blank"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  Contact us
                </Link>
              </div>
              <div className="flex  text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Get One Day Training</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Create Project Automatic</div>
              </div>
              <div className="flex  text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Speech to Gherkin Steps</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Generate Test Script</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Execute on Cloud</div>
              </div>
              <div className="flex  text-sm sm:text-base mb-1 ">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Unlimited projects</div>
              </div>
              <div className="flex  text-sm sm:text-base mb-1 ">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Unlimited Screenshots</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Video & PDF Reports</div>
              </div>
              <div className="flex  text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Test Management</div>
              </div>
            </div>
          </div>
          <div className="border-2 border-pri-500  rounded-md ">
            {/* <div className="text-sm sm:text-base md:text-lg bg-sec-500 p-3 rounded-tr-md rounded-tl-md text-white">FREE for first month</div> */}
            <div className="p-3 lg:p-5">
              <div className="text-lg sm:text-xl md:text-2xl text-center pri-font">
                API Test Automation
              </div>
              {/* <div className="text-center mb-2">
                <span className="text-4xl md:text-5xl">£49</span> /month
              </div> */}
              <div className="mt-6 mb-8 text-center">
                <Link
                  to={{
                    pathname:
                      "https://testenium.co.uk/testenium/app/contact_form.php",
                  }}
                  target="_blank"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  Contact us
                </Link>
              </div>
              <div className="flex items-center text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Get One Day Training</div>
              </div>
              <div className="flex items-center text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Create Project Automatic</div>
              </div>
              <div className="flex items-start text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />

                <div>Provide Payload and Parameters</div>
              </div>
              <div className="flex items-center text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Generate Test Script</div>
              </div>
              <div className="flex items-center text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Execute on Cloud</div>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1 ">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Unlimited projects</div>
              </div>
              <div className="flex items-center text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Comprehensive Reports</div>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1 ">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5  mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Test Management</div>
              </div>
            </div>
          </div>
          <div className="border-2 border-pri-500  rounded-md ">
            {/* <div className="text-sm sm:text-base md:text-lg bg-sec-500 p-3 rounded-tr-md rounded-tl-md text-white">FREE for first month</div> */}
            <div className="p-3 lg:p-5">
              <div className="text-lg sm:text-xl md:text-2xl text-center pri-font">
                LOAD Test Automation
              </div>
              {/* <div className="text-center mb-2">
                <span className="text-4xl md:text-5xl">£49</span> /month
              </div> */}
              <div className="mt-6 mb-8 text-center">
                <Link
                  to={{
                    pathname:
                      "https://testenium.co.uk/testenium/app/contact_form.php",
                  }}
                  target="_blank"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  Contact us
                </Link>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Get One Day Training</div>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Create Project Automatic</div>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Upload JMX & CSV File</div>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>More than 10,000 Threads</div>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Execute on Cloud</div>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Unlimited projects</div>
              </div>
              <div className="flex items-center text-sm sm:text-base mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Unlimited Virtual Users</div>
              </div>
              <div className="flex items-center text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div> BlazeMeter Reports</div>
              </div>
              <div className="flex items-center text-sm sm:text-base  mb-1">
                <FeatherIcon
                  className="text-sec-500 w-5 h-5 inline mr-1"
                  style={{ marginTop: "2px" }}
                  icon="check"
                />
                <div>Test Management</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestAutomation;
