import React, { useState } from "react";
import { Link } from "react-router-dom";

function AutomationPlatformDev(){
    const [menuItem, setMenuItem] = useState("objectives")
    

    const objectives = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Prove your skills and knowledge
            </div>
            <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
                <li>Artificial Intelligence</li>
                <li>Machine learning</li>
                <li>Deep Learning</li>
                <li>Data Science</li>
                <li>Meta Programming</li>
                <li>Component Programming</li>
                <li>Cyber Security</li>
            </ul>
             </>
    )
    const fee = (
        <>
        {/* <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Is this certification right for you?
            </div> */}
           
            <div className="text-sm sm:text-base  text-justify">
            £19,000
            </div>
                    </>
    )
    const duration = (
        <>
        {/* <div className="md:text-lg sm:text-xl font-semibold mb-2">
        The best way to learn is to do
            </div> */}
            {/* <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
                <li>No data</li>
                
            </ul> */}
            <div className="text-sm sm:text-base  text-justify">
            2 weeks
            </div>
            </>
    )
    const trainers = (
        <>
        {/* <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Test your skills and knowledge
            </div>
            <div className="text-sm sm:text-base  text-justify">
            No data
            </div> */}
            </>
    )

    return(
        <>
        <div id="top" className="w-full h-full pt-24 px-3 bg-pri-500">
      <div className="container flex items-center   h-full ">
          <div className="  text-white mt-6 py-10">
          <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl  pri-font text-white">
            <Link className="text-white hover:text-sec-500 cursor-pointer" to="/training">Training</Link> / Certification
          </div>
              <div className="pri-font hero-text tracking-wide leading-tight max-w-5xl ">
              Online Automation Platform Developer
              </div>
          </div>
      </div>
  </div>
  <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
            <div className="container">
            <div className="text-lg sm:text-xl md:text-2xl  pri-font  mb-2">
                Overview
                </div> 
                <div className="text-sm sm:text-base md:text-lg text-justify pb-5">
                A two weeks training involved theory and practical hands-on how to write meta programming to build Online Automation Platform using various latest technologies.
                </div>           
            <div className="text-lg sm:text-xl md:text-2xl  pri-font  mb-2">
            Pre-requisites
                </div> 
                <div className="text-sm sm:text-base md:text-lg text-justify ">
                Atleast 2 years experience in PHP programming with BSc in Computer Science or related areas.
                </div>           
              </div>
    </div>

    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
            <div className="container">
            <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
            Certification details
                </div>
           
                 <div>
                    <div className="flex">
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "objectives" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("objectives")
                        
                            }}
                        >Objectives</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "fee" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("fee")
                        
                            }}
                        >Fee</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "duration" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("duration")
                        
                            }}
                        >Duration</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "trainers" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("trainers")
                        
                            }}
                        >Trainers</div>

                    </div>
                <div className=" bg-white p-2 md:p-6 rounded-bl-md rounded-br-md">
                    {menuItem === "objectives" && objectives}
                    {menuItem === "fee" && fee}
                    {menuItem === "duration" && duration}
                    {menuItem === "trainers" && trainers}
                </div>
                </div>
            </div>
    </div>
    
  </>
    )
}
export default AutomationPlatformDev