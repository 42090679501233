import React from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';

function EnterprisePlatform(){
    return(
        <>
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
            <div className="container">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
                Enterprise Platform & Project Outsourcing
                </div>
                <div className="text-sm sm:text-base text-justify mb-6  mx-auto">
                Large and medium size companies may be able to affort to install Testenium Cloud platform privately or in-house. We charge a setup cost plus a recurring subscription for every user. Setup cost may vary depends on the companies requirement.          </div>
                <div className="text-sm sm:text-base text-justify   mx-auto">
                Project Outsourcing is a very convenient option which allows companies to contract us their projects for fastest & cheapest return without getting trained.</div>
            </div>
            </div>
                <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 ">
            <div className="container">
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
                
                <div className="md:col-span-2 lg:col-span-4  self-start rounded-md border-2 border-pri-500" >
                  <div className="p-3 lg:p-5">
                      <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-2">Online Testing & Encryption Services</div>
                      
                      <div className="text-center text-sm sm:text-base md:text-lg mb-4">
                      We provide the following Online Automation Services
                          </div>
                          <div className="md:grid md:grid-cols-3">
                            <div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>API Testing</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>BDD Testing</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>Protractor Testing</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>White-Box Testing</div>
                          </div>
                          </div>
                          <div>
                      <div className="flex items-center text-sm sm:text-base  mb-1">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>Selenium Testing</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base  mb-1">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>Winium Testing</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>TDD Testing</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>SQL Testing</div>
                          </div>
                          </div>
                          <div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>LOAD Testing</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>Application Security Testing</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base  mb-1">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>Searchable Encrypted Application</div>
                          </div>
                      <div className="flex items-center text-sm sm:text-base mb-1 ">
                          <FeatherIcon className="text-sec-500 w-5 h-5 inline mr-1" style={{marginTop:"2px"}} icon="check" />
                          <div>Tamil Metaprogramming</div>
                          </div>
                          </div>
                          </div>
                      <div className="my-6 mt-10 text-center">
                          <Link
                          to={{ pathname: "https://testenium.co.uk/testenium/app/contact_form.php" }} target="_blank"
                          className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                          >
                          Contact us
                          </Link>
                      </div>
                  </div>
              </div>
              <div className="  rounded-md border-2 border-pri-500" >
                  <div className="p-3 lg:p-5">
                      <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-2">Enterprise Platform</div>
                       <div className="text-center text-sm sm:text-base  md:h-16">
                      Set up cost plus GBP £29 per user/month
                          </div>
                      <div className="my-6 mt-10  text-center">
                          <Link
                         to={{ pathname: "https://testenium.co.uk/testenium/app/contact_form.php" }} target="_blank"
                          className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                          >
                          Contact us
                          </Link>
                      </div>
                  </div>
              </div>
              <div className="  rounded-md border-2 border-pri-500">
                  <div className="p-3 lg:p-5">
                      <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-2">Searchable Encryption</div>
                       <div className="text-center text-sm sm:text-base   md:h-16">
                       Price depends on project size. Turnaround time is 1 Week
                          </div>
                      <div className="my-6 mt-10 text-center">
                          <Link
                          to={{ pathname: "https://testenium.co.uk/testenium/app/contact_form.php" }} target="_blank"
                          className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                          >
                          Contact us
                          </Link>
                      </div>
                  </div>
              </div>
              <div className="rounded-md border-2 border-pri-500" >
                  <div className="p-3 lg:p-5">
                      <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-2">Project Outsourcing</div>
                       <div className="text-center text-sm sm:text-base   md:h-16">
                       Price depends on project size. Turnaround time is 48 hours
                          </div>
                      <div className="my-6 mt-10 text-center">
                          <Link
                          to={{ pathname: "https://testenium.co.uk/testenium/app/contact_form.php" }} target="_blank"
                          className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                          >
                          Contact us
                          </Link>
                      </div>
                  </div>
              </div>
              <div className="rounded-md border-2 border-pri-500"  >
                  <div className="p-3 lg:p-5">
                      <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-2">Security Testing</div>
                       <div className="text-center text-sm sm:text-base md:h-16">
                       Scan application for Vulnerabilities for £49 per month
                          </div>
                      <div className="my-6 mt-10 text-center">
                          <Link
                          to={{ pathname: "https://testenium.co.uk/testenium/app/signup.php?amt=49&opt=Security%20Testing" }} target="_blank"
                          className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                          >
                          Get started
                          </Link>
                      </div>
                  </div>
              </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default EnterprisePlatform;