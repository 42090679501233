import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import logo from "./../../assets/images/logo-white.png";
import PrivacyPolicyAndCookies from "../Modals/PrivacyPolicyAndCookies";
import { Link } from "react-router-dom";

function Footer() {
  const [privacyAndCookiesModel, setPrivacyAndCookiesModel] = useState(false);

  return (
    <>
      <div className="pt-12 pb-10 px-3 bg-pri-500 text-white">
        <div className="container text-center">
          <img className="w-40 mx-auto" src={logo} alt="Testenium Logo" />
          <div className="mb-3">
            <Link
              className="inline-block border-sec-500 border-2 rounded-md text-base my-3 py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
              to={{
                pathname:
                  "https://testenium.co.uk/testenium/app/contact_form.php",
              }}
              target="_blank"
            >
              Get in touch
            </Link>
            <div className="text-lg">Testenium Limited</div>
            <div className="text-sm">
              Company registered in England & Wales, Registration # 09367824 |
              <span
                className=" hover:text-sec-500 cursor-pointer"
                onClick={() => setPrivacyAndCookiesModel(true)}
              >
                {" "}
                Privacy and Cookies Policy
              </span>
            </div>
            <div className="text-sm">
              177 Robin Hood Way, London SW15 3QG, United Kingdom
            </div>
          </div>
        </div>
      </div>
      <hr className="text-gray-500" />
      <div className="p-3 pb-0 bg-pri-500 text-white">
        <div className="container">
          <div className="flex justify-between items-center">
            <div>
              <div className="flex">
                <div
                  onClick={() => setPrivacyAndCookiesModel(true)}
                  className="text-xs hover:text-sec-500 cursor-pointer"
                >
                  Privacy Policy
                </div>
              </div>
              <div className="text-xs">
                Copyrights 2022. All rights reserved.
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <a href="https://www.facebook.com/testeniumuk" target="_blank">
                  <div className="w-8 h-8 border-2 border-gray-500 rounded-full flex justify-center items-center hover:border-sec-500 text-gray-500 hover:text-sec-500">
                    <FeatherIcon icon="facebook" className=" w-4 h-4" />
                  </div>
                </a>
              </div>
              <div className="mr-4">
                <a href="https://twitter.com/Arasilango" target="_blank">
                  <div className="w-8 h-8 border-2 border-gray-500 rounded-full flex justify-center items-center hover:border-sec-500 text-gray-500 hover:text-sec-500">
                    <FeatherIcon icon="twitter" className=" w-4 h-4" />
                  </div>
                </a>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/testenium-limited/"
                  target="_blank"
                >
                  <div className=" w-8 h-8 border-2 border-gray-500 rounded-full flex justify-center items-center hover:border-sec-500 text-gray-500 hover:text-sec-500">
                    <FeatherIcon
                      icon="linkedin"
                      className=" w-4 h-4 text-gray-500 hover:text-sec-500"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3 bg-pri-500 text-white">
        <div className="container text-center text-xs">
          Design and developed by{" "}
          <a
            className="text-white  hover:text-sec-500 cursor-pointer"
            target="_blank"
            href="https://www.upview.tech/"
          >
            Upview Technologies
          </a>
        </div>
      </div>
      <PrivacyPolicyAndCookies
        show={privacyAndCookiesModel}
        handleClose={() => setPrivacyAndCookiesModel(false)}
      />
    </>
  );
}

export default Footer;
