import React from "react";
import DipBlockchainMetaAutomationc from "../../components/web/diplomaCourses/DipBlockchainMetaAutomationc";
import Footer from "../../components/web/Footer";

function DipBlockchainMetaAutomation() {
  return (
    <div>
      <div className="h-full">
        <DipBlockchainMetaAutomationc />
        <Footer />
      </div>
    </div>
  );
}

export default DipBlockchainMetaAutomation;
