import React from "react";

function WhyTestAutomation(){
return(
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
            <div className="container">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
                Why Test Automation using Testenium is Important?
                </div>
                <div className="text-sm sm:text-base md:text-lg text-justify pb-5">
                Organizations use numerous third-party integrations specific to their test automation needs. This will lead to many problems and obviously will be collectively expensive. Some organizations use third-party execution platforms just to execute the test automation, but they need to install all the tools, create projects, add packages and dependencies and write the test automation scripts. This is very expensive. Some testing platforms offer No-code solution and Record-and-Play tools but these have many problems.
                 </div>
                <div className="text-sm sm:text-base md:text-lg text-justify ">
                Testenium offers a meta-computing based online QAOps platform for test automation without any additional installation on the laptop or computers. Testenium supports "Speech to code" feature for BDD (Behaviour Driven Development), and Selenium WebDriver. Testenium records Gherkin steps and generates methods using BDD frameworks, implements the methods for Selenium UI test automation, executes it at scale on cloud and produces reports.
                   </div>           
              </div>
            </div>
)
}
export default WhyTestAutomation