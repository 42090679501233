import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

function TrainingCourses() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-5">
          Training & Education
        </div>
        <div className="text-lg sm:text-xl md:text-2xl text-center mb-8">
          Certification is offered for following programmes
        </div>
        <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className=" rounded-md " style={{ border: "1px solid #cbd5e0" }}>
            <div className="p-3 lg:p-5">
              <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">
                Searchable encryption platform developer
              </div>
              {/* <div className="text-center mb-2 font-semibold">
                Fee{" "}
                <span className="text-3xl md:text-4xl font-medium">£4690</span>
              </div> */}

              <div className=" text-sm sm:text-base mb-1 text-justify">
                Testenium Searchable Encryption Platform Developer must have
                expertise in setting up a platform and develop application to
                encrypt data in local computer...
              </div>

              <Link
                className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500"
                style={{ width: "fit-content" }}
                to="/sep-dev-cert"
              >
                Learn more
                <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
              </Link>

              <div className="flex justify-center mt-5">
                <Link
                  to="/sep-dev-cert"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  View the certification
                </Link>
              </div>
            </div>
          </div>
          <div className=" rounded-md " style={{ border: "1px solid #cbd5e0" }}>
            <div className="p-3 lg:p-5">
              <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">
                Test Automation Analyst
              </div>
              {/* <div className="text-center mb-2 font-semibold">
                Fee{" "}
                <span className="text-3xl md:text-4xl font-medium">£1690</span>
              </div> */}

              <div className=" text-sm sm:text-base mb-1 text-justify">
                Testenium Test Automation Analyst must have expertise in setting
                up test environment and creating test project in local computer
                and online, and...
              </div>

              <Link
                className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500"
                style={{ width: "fit-content" }}
                to="/automation-analyst-cert"
              >
                Learn more
                <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
              </Link>

              <div className="flex justify-center mt-5">
                <Link
                  to="/automation-analyst-cert"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  View the certification
                </Link>
              </div>
            </div>
          </div>
          <div className=" rounded-md " style={{ border: "1px solid #cbd5e0" }}>
            <div className="p-3 lg:p-5">
              <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">
                Test Automation Architect
              </div>
              {/* <div className="text-center mb-2 font-semibold">
                Fee{" "}
                <span className="text-3xl md:text-4xl font-medium">£1790</span>
              </div> */}

              <div className=" text-sm sm:text-base mb-1 text-justify">
                Testenium Test Automation Architect must have expertise in
                setting up test environment and creating test project in local
                computer and online, writing...
              </div>

              <Link
                className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500"
                style={{ width: "fit-content" }}
                to="/automation-architect-cert"
              >
                Learn more
                <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
              </Link>

              <div className="flex justify-center mt-5">
                <Link
                  to="/automation-architect-cert"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  View the certification
                </Link>
              </div>
            </div>
          </div>
          <div className=" rounded-md " style={{ border: "1px solid #cbd5e0" }}>
            <div className="p-3 lg:p-5">
              <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">
                Test Automation and Azure DevOps Engineer
              </div>
              {/* <div className="text-center mb-2 font-semibold">
                Fee{" "}
                <span className="text-3xl md:text-4xl font-medium">£2490</span>
              </div> */}

              <div className=" text-sm sm:text-base mb-1 text-justify">
                Testenium Test Automation & DevOps Engineer must have expertise
                in setting up test environment, working on Azure DevOps and
                creating test project...
              </div>

              <Link
                className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500"
                style={{ width: "fit-content" }}
                to="/devops-eng-cert"
              >
                Learn more
                <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
              </Link>

              <div className="flex justify-center mt-5">
                <Link
                  to="/devops-eng-cert"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  View the certification
                </Link>
              </div>
            </div>
          </div>

          {/* <div className=" rounded-md " style={{border:"1px solid #cbd5e0"}}>
                  <div className="p-3 lg:p-5">
                      <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">Test Automation Engineer</div>
                      <div className="text-center mb-2 font-semibold">Fee <span className="text-3xl md:text-4xl font-medium">£1490</span></div>

                            
                      <div className=" text-sm sm:text-base mb-1 text-justify">
                      Testenium Test Automation Engineer must have expertise in setting up test environment and creating test project in local computer and online... 
                      </div >
                          
                    <Link className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500" style={{width:"fit-content"}}
                          to="/automation-eng-cert">
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </Link>
                          
                          <div className="flex justify-center mt-5">
                          <Link
                                to="/automation-eng-cert"
                                className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                                >
                                 View the certification
                                </Link>
                      </div>
                  </div>
              </div> */}
          {/* <div className=" rounded-md " style={{border:"1px solid #cbd5e0"}}>
                  <div className="p-3 lg:p-5">
                      <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">Online Automation Platform Developer</div>
                      <div className="text-center mb-2 font-semibold">Fee <span className="text-3xl md:text-4xl font-medium">£9000</span></div>

                            
                      <div className=" text-sm sm:text-base mb-1 text-justify">
                      A two weeks training involved theory and practical hands-on how to write meta programming to build Online Automation Platform using various latest... 
                      </div >
                          
                    <Link className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500" style={{width:"fit-content"}}
                          to="/automation-platform-dev-cert">
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </Link>
                          
                          <div className="flex justify-center mt-5">
                                <Link
                                to="/automation-platform-dev-cert"
                                className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                                >
                                 View the certification
                                </Link>
                      </div>
                  </div>
              </div> */}
        </div>
      </div>
    </div>
  );
}

export default TrainingCourses;
