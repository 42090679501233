import React from "react";
import one from "./../../assets/images/partners/global_frontiers.jpg";
import two from "./../../assets/images/partners/explarent.jpg";
import three from "./../../assets/images/partners/srs.png";
import four from "./../../assets/images/partners/bgs.png";


function Partners() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-8">
          Business Development Partners
        </div>
        <div className="flex items-center">
          <img className={"h-6 md:h-12 lg:h-16 mx-auto"} src={one} alt={"Trakee"} />
          <img className={"h-6 md:h-12 lg:h-16 mx-auto"} src={two} alt={"Trakee"} />
          <img className={"h-6 md:h-12 lg:h-16 mx-auto"} src={three} alt={"Trakee"} />
          <img className={"h-5 md:h-10 lg:h-12 mx-auto"} src={four} alt={"Trakee"} />
        </div>
      </div>
    </div>
  );
}

export default Partners;
