
import React from "react";
import EnterprisePlatform from "../components/web/EnterprisePlatform";
import Footer from "../components/web/Footer";
import PricingHero from "../components/web/PricingHero";
import ProtectingDocs from "../components/web/ProtectingDocs";
import TestAutomation from "../components/web/TestAutomation";


function Pricing() {
  return (
    <div>
      <PricingHero/>
      <TestAutomation/>
      <ProtectingDocs/>
      <EnterprisePlatform/>
      <Footer/>
    </div>
  );
}

export default Pricing;
