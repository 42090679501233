import React from "react";


function EncryptionHero(){
    return(
      <div id="top" className="w-full h-full pt-24  bg-pri-500">
      <div className="container flex items-center justify-center  h-full px-3 ">
          <div className="text-center  text-white mt-6">
              <div className="pri-font hero-text tracking-wide leading-tight  max-w-xl py-10">
              Next generation encryption platform
              </div>
          </div>
      </div>
  </div>
    )
}

export default EncryptionHero;