import React, { useState } from "react";
import { Link } from "react-router-dom";

function AutomationAnalyst() {
  const [menuItem, setMenuItem] = useState("objectives");

  const objectives = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Prove your skills and knowledge
      </div>
      <div className="text-sm sm:text-base  text-justify mb-2">
        Provides participants with the knowledge and skills necessary to design
        and build a test automation and API testing project and produce test
        reports. This training course focuses on the object oriented principles,
        programming and testing concepts methods, testing APIs, using AMPT
        feature in Testenium, using Testenium for continuous integration,
        testing tools, and processes for automating web applications and dynamic
        functional tests and the relationship of those tests to test management,
        configuration management, defect management, software development
        processes and quality assurance.
      </div>
      <div className="text-sm sm:text-base  text-justify">
        Methodologies mentioned are generally applicable across many software
        lifecycle approaches such as agile, types of systems and test types
        (functional and non-functional testing).{" "}
      </div>
    </>
  );
  const audience = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Is this certification right for you?
      </div>
      <div className="text-sm sm:text-base  text-justify mb-2">
        This 2-day training programme is beneficial for candidates in roles such
        as testers, test analysts, test engineers, test consultants, test
        managers, software developers, and anyone wishing to gain Testenium
        Certified: Test Automation Engineer certificate. This hands-on training
        course may also be suitable for anyone working in relted field, who
        wants a deeper understanding of software test automation.
      </div>
      <div className="text-sm sm:text-base  text-justify">
        The certification is aimed at professionals who are working within a
        tool supported software testing environment and non-tool supported
        online test automation platform. It is also for very much suitable for
        manual testers who are planning to start working within a tool supported
        software testing environment and non-tool supported online test
        automation platform, or setting up a testing company in the future.
      </div>
    </>
  );
  const contents = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        The best way to learn is to do
      </div>
      <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
        <li>Programming Concepts</li>
        <li>Object Oriented Programming</li>
        <li>
          Creating projects and defining classes and creating and using objects
          (C# or Java)
        </li>
        <li>Introduction to Testing Frameworks (TDD, BDD, Selenium)</li>
        <li>Selenium Components (CORE, IDE, RC, WebDriver)</li>
        <li>Selenium WebDriver Projects</li>
        <li>Implementing Page Object Model</li>
        <li>Design and build test automation frameworks</li>
        <li>API Testing</li>
        <li>Using Testenium to create and test projects</li>
        <li>Continuous Integration with Testenium</li>
        <li>Accelerated Massive Parallel Test (AMPT)</li>
      </ul>
    </>
  );
  const exam = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Test your skills and knowledge
      </div>
      <div className="text-sm sm:text-base  text-justify">
        The Test Automation Engineer exam comprises 20 multiple choice questions
        (40% of test) completed within 30 minutes and one test project (60% of
        test) completed within 30 minutes, with a pass mark grade of 70%.
      </div>
    </>
  );

  return (
    <>
      <div id="top" className="w-full h-full pt-24 px-3 bg-pri-500">
        <div className="container flex items-center   h-full ">
          <div className="  text-white mt-6 py-10">
            <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl  pri-font text-white">
              <Link
                className="text-white hover:text-sec-500 cursor-pointer"
                to="/training"
              >
                Training
              </Link>{" "}
              / Certification
            </div>
            <div className="pri-font hero-text tracking-wide leading-tight max-w-5xl ">
              Test Automation Analyst
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
        <div className="container">
          <div className="text-lg sm:text-xl md:text-2xl  pri-font  mb-2">
            Overview
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify ">
            Testenium Test Automation Analyst must have expertise in setting up
            test environment and creating test project in local computer and
            online, and analysing the test requirements and creating test design
            frameworks.{" "}
          </div>
        </div>
      </div>

      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
          <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
            Certification details
          </div>

          <div>
            <div className="flex">
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "objectives" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("objectives");
                }}
              >
                Objectives
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "audience" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("audience");
                }}
              >
                Audience
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "contents" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("contents");
                }}
              >
                Contents
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "exam" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("exam");
                }}
              >
                Exam
              </div>
            </div>
            <div className=" bg-white p-2 md:p-6 rounded-bl-md rounded-br-md">
              {menuItem === "objectives" && objectives}
              {menuItem === "audience" && audience}
              {menuItem === "contents" && contents}
              {menuItem === "exam" && exam}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutomationAnalyst;
