import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import logo from "./../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";

function NavBar() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const [expandService, setExpandService] = useState(false);
  const [expandAcademy, setExpandAcademy] = useState(false);

  useEffect(() => {
    /*To show the navbar after scrolling*/
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > 320) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  const services = (
    <Menu
      items={[
        {
          key: "1",
          label: <Link to="/encryption">Encryption</Link>,
        },
        {
          key: "2",
          label: <Link to="/testing">Testing</Link>,
        },
      ]}
    />
  );
  const academy = (
    <Menu
      items={[
        {
          key: "1",
          label: <Link to="/training">Training</Link>,
        },
        {
          key: "2",
          label: <Link to="/diploma-courses">Diploma courses</Link>,
        },
        {
          key: "3",
          label: <Link to="/degree-courses">Degree courses</Link>,
        },
      ]}
    />
  );

  const serviceItems = (
    <>
      <Link
        onClick={() => {
          setMobileMenu(false);
          setExpandService(false);
          setExpandAcademy(false);
        }}
        className="block ml-4 lg:ml-6 my-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
        to="/encryption"
      >
        Encryption
      </Link>

      <Link
        onClick={() => {
          setMobileMenu(false);
          setExpandService(false);
          setExpandAcademy(false);
        }}
        className="block ml-4 lg:ml-6 my-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
        to="/testing"
      >
        Testing
      </Link>
    </>
  );
  const academyItems = (
    <>
      <Link
        onClick={() => {
          setMobileMenu(false);
          setExpandAcademy(false);
          setExpandService(false);
        }}
        className="block ml-4 lg:ml-6 my-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
        to="/training"
      >
        Training
      </Link>
      <Link
        onClick={() => {
          setMobileMenu(false);
          setExpandAcademy(false);
          setExpandService(false);
        }}
        className="block ml-4 lg:ml-6 my-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
        to="/diploma-courses"
      >
        Diploma courses
      </Link>
      <Link
        onClick={() => {
          setMobileMenu(false);
          setExpandAcademy(false);
          setExpandService(false);
        }}
        className="block ml-4 lg:ml-6 my-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
        to="/degree-courses"
      >
        Degree courses
      </Link>
    </>
  );

  return (
    <div
      className={
        "bg-pri-50 px-3 py-2 w-full bg-white z-40 " +
        (scrolled ? "fixed shadow-md" : "absolute")
      }
      style={!scrolled ? { marginTop: "38px" } : { marginTop: "0px" }}
    >
      <div className="container">
        <div className="flex justify-between items-center">
          <Link
            onClick={() => setMobileMenu(false)}
            to="home"
            className="cursor-pointer"
          >
            <img className="w-48" src={logo} alt="Testenium Logo" />
          </Link>
          <div>
            <div className="items-center hidden lg:flex">
              <Link
                to="/about-us"
                className="mx-2 lg:mx-3 whitespace-nowrap text-sm lg:text-base text-pri-500 cursor-pointer hover:text-sec-500"
              >
                About us
              </Link>
              <Dropdown overlay={services}>
                <div className="flex items-center mx-2 lg:mx-3 whitespace-nowrap text-sm lg:text-base text-pri-500 cursor-pointer hover:text-sec-500">
                  Services &nbsp;
                  <DownOutlined />
                </div>
              </Dropdown>
              <Dropdown overlay={academy}>
                <div className="flex items-center mx-2 lg:mx-3 whitespace-nowrap text-sm lg:text-base text-pri-500 cursor-pointer hover:text-sec-500">
                  Academy &nbsp;
                  <DownOutlined />
                </div>
              </Dropdown>
              <Link
                to="/pricing"
                className="mx-2 lg:mx-3  text-sm lg:text-base text-pri-500 cursor-pointer hover:text-sec-500"
              >
                Pricing
              </Link>
              <Link
                to={{
                  pathname:
                    "https://testenium.co.uk/testenium/app/contact_form.php",
                }}
                target="_blank"
                className="mx-2 lg:mx-3 whitespace-nowrap text-sm lg:text-base text-pri-500 cursor-pointer hover:text-sec-500"
              >
                Contact us
              </Link>
              <Link
                to={{
                  pathname: "https://testenium.co.uk/testenium/app/login.html",
                }}
                target="_blank"
                className="mx-2 lg:mx-3 whitespace-nowrap text-sm lg:text-base text-pri-500 cursor-pointer hover:text-sec-500"
              >
                Sign in
              </Link>
              <Link
                to={{
                  pathname:
                    "https://testenium.co.uk/testenium/app/signup.php?amt=0&opt=trial",
                }}
                target="_blank"
                className="ml-2 lg:ml-3 whitespace-nowrap text-sm lg:text-base text-white border-2 border-sec-500 py-2 px-8 rounded-md bg-sec-500 cursor-pointer hover:bg-white hover:text-sec-500"
              >
                Sign up
              </Link>
            </div>
            <div
              onClick={() => setMobileMenu(!mobileMenu)}
              className="lg:hidden"
            >
              <FeatherIcon icon="menu" className="w-8 h-8" />
            </div>
          </div>
        </div>

        {mobileMenu && (
          <div className="mt-3">
            <Link
              to="/about-us"
              onClick={() => {
                setMobileMenu(false);
                setExpandAcademy(false);
                setExpandService(false);
              }}
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              About us
            </Link>

            <div
              onClick={() => {
                setExpandService(!expandService);
              }}
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              Services
            </div>
            <div>{expandService === true && serviceItems}</div>

            <div
              onClick={() => {
                setExpandAcademy(!expandAcademy);
              }}
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              Academy
            </div>
            <div>{expandAcademy === true && academyItems}</div>

            <Link
              onClick={() => {
                setMobileMenu(false);
                setExpandAcademy(false);
                setExpandService(false);
              }}
              to="pricing"
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              Pricing
            </Link>
            <Link
              onClick={() => {
                setMobileMenu(false);
                setExpandAcademy(false);
                setExpandService(false);
              }}
              to={{
                pathname:
                  "https://testenium.co.uk/testenium/app/contact_form.php",
              }}
              target="_blank"
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              Contact us
            </Link>
            <Link
              onClick={() => {
                setMobileMenu(false);
                setExpandAcademy(false);
                setExpandService(false);
              }}
              to={{
                pathname: "https://testenium.co.uk/testenium/app/login.html",
              }}
              target="_blank"
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              Sign in
            </Link>
            <Link
              onClick={() => {
                setMobileMenu(false);
                setExpandAcademy(false);
                setExpandService(false);
              }}
              to={{
                pathname:
                  "https://testenium.co.uk/testenium/app/signup.php?amt=0&opt=trial",
              }}
              target="_blank"
              className="block m-2 text-base text-pri-900 border-2 border-pri-500 py-2 px-5 rounded-md cursor-pointer hover:bg-pri-500 hover:text-white text-center"
            >
              Sign up
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
