import React from "react";
import Footer from "../components/web/Footer";
import AboutUsHero from "../components/web/AboutUsHero";
import AboutUsTestimonials from "../components/web/AboutUsTestimonials";
import Awards from "../components/web/Awards";
import About from "../components/web/About";

function AboutUs() {
  return (
    <div>
      <div className="h-full">
        <AboutUsHero/>
        <About/>
        <Awards/>
        <AboutUsTestimonials/>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
