import React from "react";
import EncryptionProblem from "../components/web/EncryptionProblem";
import EncryptionServices from "../components/web/EncryptionServices";
import EncryptionHero from "../components/web/EncryptionHero";
import Footer from "../components/web/Footer";
import NextGenDocEncryption from "../components/web/NextGenDocEncryption";
import SearchableEncryption from "../components/web/SearchableEncryption";
import TamilMetaProgramming from "../components/web/TamilMetaProgramming";
import WhyEncryption from "../components/web/WhyEncryption";

function Encryption(){
    return(
        <div>
            <div className="h-full">
                <EncryptionHero/>
                <EncryptionServices/>
                <WhyEncryption/>
                <EncryptionProblem/>
                <TamilMetaProgramming/>
                <SearchableEncryption/>
                <NextGenDocEncryption/>
                <Footer/>
            </div>
        </div>
    )
}
export default Encryption