import React, { useState } from "react";
import ses from "./../../assets/images/SES.png";
import FeatherIcon from "feather-icons-react";
import EncryptionMoreModal from "../Modals/EncryptionMoreModal";


function SearchableEncryption(){
    const [more,setMore] = useState(false)
    const [moreOf, setMoreOf] = useState("searchableEncryption")
    return(
        <>
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
        <div className="container">
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl pri-font text-pri-900 mb-3">
              Searchable Encryption
              </div>
              <div className="text-sm sm:text-base md:text-lg mb-6 max-w-5xl mx-auto">
              Our platform provides for encrypting companies’ databases and makes the encrypted data searchable using Homomorphic and AES encryption schemes. The data is displayed on the user interface. The plain data is...
              </div>
              
                
                  <div
                    onClick={() => {
                      setMore(true);
                      setMoreOf("searchableEncryption");
                    }}
                    className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500" style={{width:"fit-content"}}
                  >
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </div>
                
             
            </div>
            <div className="hidden md:block flex-1">
              <div className=" mx-auto max-w-md ">
                <img className="" src={ses} alt="Web app development" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EncryptionMoreModal
      visible={more}
      handleCancel={()=>setMore(false)}
      more={moreOf}
      />              
      </>
    )
}

export default SearchableEncryption;