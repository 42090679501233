import React from "react";
// import Divider from "../components/Divider";
import Features from "../components/web/Features";
import Footer from "../components/web/Footer";
import Hero3 from "../components/web/Hero3";
import Process from "../components/web/Process";
import Services from "../components/web/Services";
import Testimonials from "../components/web/Testimonials";
import Careers from "../components/web/Careers";
import Customers from "../components/web/Customers";
import Comparison from "../components/web/Comparison";
import Partners from "../components/web/Partners";
import RequestDemo from "../components/web/RequestDemo";
import TechPartners from "../components/web/TechPartners";
import StrategicPartners from "../components/web/StrategicPartners";

function Home(props) {
  return (
    <div className="h-full">
      <Hero3 />
      <Services />
      <Process />

      <Careers />
      <Features />
      <RequestDemo />
      <Customers />
      <Testimonials />
      <Partners />
      <TechPartners />
      <StrategicPartners />
      <Footer />
    </div>
  );
}

export default Home;
