import React, { useState } from "react";
import { Link } from "react-router-dom";

function SEPDeveloper(){
    const [menuItem, setMenuItem] = useState("objectives")
    

    const objectives = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Prove your skills and knowledge
            </div>
            <div className="text-sm sm:text-base  text-justify">
            Provides participants with the knowledge and skills necessary to design and build searchable encryption platform. This training course focuses on the object oriented principles, socket programming and testing concepts. The candidate will be able to understad Security Architecture and Implementation, Homomorphic Encryption, Data Privacy and Protection, Cyber Security of Smart Grid.
           </div>
             </>
    )
    const audience = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Is this certification right for you?
            </div>
            <div className="text-sm sm:text-base  text-justify mb-2">
            This 5-days training programme is beneficial for candidates in roles such as Socket Programmers, Cyber Security Specialists, Cyber Security Analysts. This hands-on training course may also be suitable for anyone working in related field, who wants a deeper understanding of Cyber Security and Data Privacy and Protection.
            </div>
            <div className="text-sm sm:text-base  text-justify">
            The certification is aimed at professionals who are working within a Searchable Encryption platform development environment. It is also for very much suitable for junior socket programmers who are planning to start working within Cyber Security related tools and Searchable Encryption Platform environment.
            </div>
                    </>
    )
    const contents = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        The best way to learn is to do
            </div>
            <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
            <li>Concepts of port communication</li>
                <li>Sockets and their use</li>
                <li>WebSockets</li>
                <li>Echo Client and Server</li>
                <li>Data transfer Client and Server</li>
                <li>HTTP protocol</li>
                <li>Building HTTP server</li>
                <li>Communication Breakdown</li>
                <li>Handling Multiple Connections</li>
                <li>Running the Multi-Connection Client and Server</li>
                <li>Encryption Techniques</li>
                <li>Symmetric and Asymetric Encryption</li>
                <li>Homomorphic Encryption</li>
                <li>Network Java Programming</li>
                <li>Setting up a databse for encrypted data</li>
                <li>Writing client/server applications</li>
                <li>Data transformation and integration</li>
                <li>Automate Excel data for Encryption</li>
                <li>Update databases with encrypted data</li>
                <li>Developing User Interfaces using Java Swing</li>
                <li>Testing the Encrypted Platform</li>
            </ul>
            </>
    )
    const exam = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Test your skills and knowledge
            </div>
            <div className="text-sm sm:text-base  text-justify">
            The Testenium Certified: Searchable Encryption Platform Developer exam comprises 20 multiple choice questions (40% of test) completed within 30 minutes and one test project (60% of test) completed within 30 minutes, with a pass mark grade of 70%.
            </div>
            </>
    )

    return(
        <>
        <div id="top" className="w-full h-full pt-24 px-3 bg-pri-500">
      <div className="container flex items-center   h-full ">
          <div className="  text-white mt-6 py-10">
          <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl  pri-font text-white">
            <Link className="text-white hover:text-sec-500 cursor-pointer" to="/training">Training</Link> / Certification
          </div>
              <div className="pri-font hero-text tracking-wide leading-tight max-w-5xl ">
              Searchable Encryption Platform Developer
              </div>
          </div>
      </div>
  </div>
  <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
            <div className="container">
            <div className="text-lg sm:text-xl md:text-2xl  pri-font  mb-2">
                Overview
                </div> 
                <div className="text-sm sm:text-base md:text-lg text-justify pb-5">
                Testenium Searchable Encryption Platform Developer must have expertise in setting up a platform and develop application to encrypt data in local computer and send them to remote server or cloud, developing socket based applications in java programming languages and front end interface in order to exchange encrypted data between the client and the server. The applcation will read data from excel, databases or from CSV files and encrypt them in the client before sending them to the Server. The encrypted data then will be stored in the database.
                </div>
                 </div>
    </div>

    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
            <div className="container">
            <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
            Certification details
                </div>
           
                 <div>
                    <div className="flex">
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "objectives" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("objectives")
                        
                            }}
                        >Objectives</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "audience" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("audience")
                        
                            }}
                        >Audience</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "contents" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("contents")
                        
                            }}
                        >Contents</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "exam" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("exam")
                        
                            }}
                        >Exam</div>

                    </div>
                <div className=" bg-white p-2 md:p-6 rounded-bl-md rounded-br-md">
                    {menuItem === "objectives" && objectives}
                    {menuItem === "audience" && audience}
                    {menuItem === "contents" && contents}
                    {menuItem === "exam" && exam}
                </div>
                </div>
            </div>
    </div>
    
  </>
    )
}

export default SEPDeveloper

