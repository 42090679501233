import React from "react";
import FeatherIcon from "feather-icons-react";

function Comparison() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3" id="technologies">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font">
          Comparison with competitors
        </div>
        <div className="text-sm sm:text-base md:text-lg  text-center pb-5">
          Unbeatable one-stop Test Automation Cloud platform for code
          generation, execution and test management
        </div>

        <div className="max-w-4xl mx-auto">
          <table className="w-full">
            <tr>
              <th className="hidden md:block text-xl pri-font text-left pb-2">
                Features
              </th>
              <th className="text-sm md:text-xl pri-font pb-2">Testenium</th>
              <th className="text-sm md:text-xl pri-font pb-2">Lamdatest</th>
              <th className="text-sm md:text-xl pri-font pb-2">Browserstack</th>
            </tr>
            <tr>
              <td colSpan={3} className="md:hidden text-base py-2 text-center">
                Code Generation
              </td>
            </tr>
            <tr>
              <td className="hidden md:block text-lg py-2">Code Generation</td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="md:hidden text-base py-2 text-center">
                Speech to Code (Voice)
              </td>
            </tr>
            <tr>
              <td className="hidden md:block text-lg py-2">
                Speech to Code (Voice)
              </td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="md:hidden text-base py-2 text-center">
                Accelerated Massive Parallel Testing
              </td>
            </tr>
            <tr>
              <td className="hidden md:block text-lg py-2">
                Accelerated Massive Parallel Testing
              </td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="md:hidden text-base py-2 text-center">
                PDF Reports
              </td>
            </tr>
            <tr>
              <td className="hidden md:block text-lg py-2">PDF Reports</td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="md:hidden text-base py-2 text-center">
                Automatic Project Creation
              </td>
            </tr>
            <tr>
              <td className="hidden md:block text-lg py-2">
                Automatic Project Creation
              </td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="md:hidden text-base py-2 text-center">
                Unlimited Projects on Cloud
              </td>
            </tr>
            <tr>
              <td className="hidden md:block text-lg py-2">
                Unlimited Projects on Cloud
              </td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="x-circle"
                  className="text-red-500 w-6 h-6 mx-auto"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="md:hidden text-base py-2 text-center">
                Execute Projects remotely
              </td>
            </tr>
            <tr>
              <td className="hidden md:block text-lg py-2">
                Execute Projects remotely
              </td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
              <td>
                <FeatherIcon
                  icon="check-circle"
                  className="text-sec-500 w-6 h-6 mx-auto"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Comparison;
