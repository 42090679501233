import React, { useState } from "react";
import { Link } from "react-router-dom";

function DipMetaMedicalInformaticsc() {
  const [menuItem, setMenuItem] = useState("objectives");

  const objectives = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Prove your skills and knowledge
      </div>
      <div className="text-sm sm:text-base  text-justify mb-2">
        Entry Requirement: MBBS - Bachelor of Medicine and Surgery
      </div>
    </>
  );
  const audience = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Is this certification right for you?
      </div>
      <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
        <li>Medical Doctors</li>
        <li>Surgeons</li>
      </ul>
    </>
  );
  const contents = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        The best way to learn is to do
      </div>
      <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
        <li>Introduction to Meta Programming</li>
        <li>Meta Computing for Medical Applications</li>
        <li> Fundamentals of data science in medicine</li>
        <li>Advanced data analysis in medicine</li>
        <li>Data driven decision making</li>
        <li>Big Data for Medicine</li>
        <li>Meta Analytics in Medicine</li>
        <li>Research Methods in medical informatics</li>
      </ul>
    </>
  );
  const exam = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Receive your recognition
      </div>
      <div className="text-sm sm:text-base  text-justify">
        Postgraduate Diploma Medical Informatics
      </div>
    </>
  );

  return (
    <>
      <div id="top" className="w-full h-full pt-24 px-3 bg-pri-500">
        <div className="container flex items-center   h-full ">
          <div className="  text-white mt-6 py-10">
            <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl  pri-font text-white">
              <Link
                className="text-white hover:text-sec-500 cursor-pointer"
                to="/diploma-courses"
              >
                Diploma courses
              </Link>{" "}
              / Certification
            </div>
            <div className="pri-font hero-text tracking-wide leading-tight max-w-5xl ">
              Postgraduate Diploma in Meta Medical Informatics (Online)
            </div>

            <Link
              to={{
                pathname: "https://lnkd.in/eTaS2kEg",
              }}
              target="_blank"
              className="inline-block mt-5 whitespace-nowrap text-sm lg:text-base text-white border-2 border-sec-500 py-2 px-8 rounded-md bg-sec-500 cursor-pointer hover:bg-white hover:text-sec-500"
            >
              Apply now
            </Link>
          </div>
        </div>
      </div>
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
        <div className="container">
          <div className="text-lg sm:text-xl md:text-2xl  pri-font  mb-2">
            Why do I need to learn Meta-Automation for Medicine?
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify mb-2">
            Big data analytics has changed how we manage, analyze, and leverage
            data across industries. One of the most notable sectors where data
            analytics is making significant changes is Medicine. When use Meta
            Automation, data analytics in Medicine can reduce treatment costs,
            offer a comprehensive insight into patients and conditions that
            affect or have the potential to affect them, and improve the quality
            of life in general.
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify ">
            The industry is using a lots of tool and employ programmers to
            develop Medical Analytical models and produce reports. On top of
            this, the companies have to spend money on heavy hardware to execute
            the project and to install all the tools. These expenses are not
            necessary if you go for meta-automation. Meta automation is simply a
            concept of "Automating the Automation". If there is a platform like
            testenium.com which has been regarded as the most innovative
            automation platform, you do not need to write the code for any
            analytical task. In testenium.com you only need to provide the data
            by way of uploading the data in text file or excel file .
            Testenium.com will create project, write the code (100% accurate),
            execute on cloud at scale and produce Analytical reports, so that
            you can save over 90% of the time in creating analytical reports.
            The future is Meta-computing and Meta-automation and the general
            high level programming is going to be obsolete soon with the
            introduction of Meta-computing and meta-programming anyway.
          </div>
          <div className="text-lg sm:text-xl md:text-2xl  pri-font mt-3  mb-2">
            What do I get?
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify ">
            One Year FREE access to Testenium.com Meta-automation Platform with
            this course.
          </div>
        </div>
      </div>

      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
          <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
            Certification details
          </div>

          <div>
            <div className="flex">
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "objectives" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("objectives");
                }}
              >
                Requirements
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "audience" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("audience");
                }}
              >
                Audience
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "contents" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("contents");
                }}
              >
                Contents
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "exam" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("exam");
                }}
              >
                Award
              </div>
            </div>
            <div className=" bg-white p-2 md:p-6 rounded-bl-md rounded-br-md">
              {menuItem === "objectives" && objectives}
              {menuItem === "audience" && audience}
              {menuItem === "contents" && contents}
              {menuItem === "exam" && exam}
            </div>
          </div>
          <div className="text-center mt-5">
            {/* <Link
              to={{
                pathname: "https://lnkd.in/ehgBNeDV",
              }}
              target="_blank"
              className="inline-block border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
            >
              Get started now
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default DipMetaMedicalInformaticsc;
