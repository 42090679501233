import React from "react";
import { Link } from "react-router-dom";
// import CareerRoles from "../Modals/CareerRoles";

function RequestDemo() {
  // const [careerModal, setCareerModal] = useState(false);
  // const [careerType, setCareerType] = useState("");

  return (
    <>
      <div
        className="py-8 sm:py-10 md:py-12 lg:py-16 px-3 bg-pri-500"
        id="careers"
      >
        <div className="container">
          <div className="md:flex justify-between gap-4 items-center">
            <div className="max-w-3xl">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-3xl text-center md:text-left pri-font text-white mb-6 md:mb-0">
                Millions of lines of code are generated every second.
              </div>
              <div className="text-base md:text-lg text-center md:text-left text-white mb-6 md:mb-0">
                Unbeatable one-stop Test Automation Cloud platform for code
                generation, execution and test management.
              </div>
            </div>
            <div className="text-center mb-3 md:mb-0">
              <Link
                to={{ pathname: "https://testenium.co.uk/testenium/app/contact_form.php" }} target="_blank" 
                className="whitespace-nowrap border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
              >
                Request a demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestDemo;
