import React from "react";

function EncryptionProblem(){
    return(
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
            <div className="container">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
                Problems in the industry
                </div>
                <div className="text-sm sm:text-base md:text-lg text-justify ">
                One of the most common problems with encryption is that the documents or files cannot be decrypted if the user lost the key. When using full disk encryption users can be locked out of their computers and unable to work because they have forgotten their password or lost their second-factor authentication credentials.                </div>
            </div>
        </div>
    )
}

export default EncryptionProblem