import React from "react";


function TestingTypes(){
    return(
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
              <div className="container">
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="border rounded-md bg-white shadow-md">
                        <div className="p-3 lg:p-5">
                            <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-1">
                                API testing</div>
                            <div className="text-sm sm:text-base text-center mb-1">
                            Testenium offers an API Test Automation platform which enables testing APIs at scale without manually handling the Auth Token</div>
                        </div>
                        </div>
                        <div className="border rounded-md bg-white  shadow-md">
                        <div className="p-3 lg:p-5">
                            <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-1">
                            LOAD testing
                            </div>
                            <div className="text-sm sm:text-base text-center mb-1">
                            With Testenium a user will be able to test the performance of the application for unlimited virtual users.</div>
                        </div>
                        </div>
                        <div className="border rounded-md bg-white shadow-md">
                        <div className="p-3 lg:p-5">
                            <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-1">
                                Security testing</div>
                            <div className="text-sm sm:text-base text-center mb-1">
                            Testenium offers a very easy way of performing the testing for any kind of vulnerablities</div>
                        </div>
                        </div>
                        <div className="border rounded-md bg-white shadow-md">
                        <div className="p-3 lg:p-5">
                            <div className="text-lg sm:text-xl md:text-2xl text-center pri-font mb-1">
                                Code coverage</div>
                            <div className="text-sm sm:text-base text-center mb-1">
                            Simply upload source code of the applications along with unit testing module in Testenium for code coverage report</div>
                        </div>
                        </div>
                    </div>
              </div>
        </div>
    )
}

export default TestingTypes