import React, { useState } from "react";
import { Link } from "react-router-dom";

function MscSoftwareTesting() {
  const [menuItem, setMenuItem] = useState("objectives");

  const objectives = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Prove your skills and knowledge
      </div>
      <div className="text-sm sm:text-base  text-justify mb-2">
        Entry Requirement: BSc degree in computing or equivalent
      </div>
    </>
  );
  const audience = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Is this certification right for you?
      </div>
      <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
        <li>Software Testers (Manual)</li>
        <li>Junior Software Test Automation Engineers</li>
        <li>Those who wants to pursue their career in software testing</li>
      </ul>
    </>
  );
  const contents = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        The best way to learn is to do
      </div>
      <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
        <li>Meta-computing</li>
        <li>Meta-programming and Meta-Automation</li>
        <li>Cloud Computing for Meta automation</li>
        <li>Virtual Encryption in Meta-automation</li>
        <li>Testenium Meta-Automation Platform and Features</li>
        <li>Software Testing in Meta-automation</li>
        <li>Functional and Non-functional testing in Testenium</li>
        <li>Research Method /Report</li>
      </ul>
    </>
  );
  const exam = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Receive your recognition
      </div>
      <div className="text-sm sm:text-base  text-justify">
        Executive Master degree in Software Testing Meta-automation
      </div>
    </>
  );

  return (
    <>
      <div id="top" className="w-full h-full pt-24 px-3 bg-pri-500">
        <div className="container flex items-center   h-full ">
          <div className="  text-white mt-6 py-10">
            <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl  pri-font text-white">
              <Link
                className="text-white hover:text-sec-500 cursor-pointer"
                to="/degree-courses"
              >
                Degree courses
              </Link>{" "}
              / Certification
            </div>
            <div className="pri-font hero-text tracking-wide leading-tight max-w-5xl ">
              Executive Master in Software Testing Meta-Automation (Online)
            </div>

            <Link
              to={{
                pathname: "https://lnkd.in/grKDyTeT",
              }}
              target="_blank"
              className="inline-block mt-5 whitespace-nowrap text-sm lg:text-base text-white border-2 border-sec-500 py-2 px-8 rounded-md bg-sec-500 cursor-pointer hover:bg-white hover:text-sec-500"
            >
              Apply now
            </Link>
          </div>
        </div>
      </div>
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
        <div className="container">
          <div className="text-lg sm:text-xl md:text-2xl  pri-font  mb-2">
            Why do I need to learn Executive Master in Software Testing
            Meta-Automation?
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify ">
            The programme is designed to equip manual testers, junior test
            engineers and test managers with a broad range of software test
            plan, design and development skills and knowledge so that they will
            have a good understanding of test automation frameworks and test any
            applications effectively. With the wide range of skills, they have
            learned from the programme, they should also be able to manage their
            test automation projects more efficiently. This programme has been
            customised for software testing professionals across the software
            industry to develop effective testing strategies and test automation
            projects with knowledge and skills that are needed to produce
            quality software.
          </div>
          <div className="text-lg sm:text-xl md:text-2xl  pri-font mt-3  mb-2">
            What do I get?
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify ">
            Executive Master degree in Software Testing Meta-automation
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify ">
            The students will be able to use TESTENIUM PLATFORM for testing
            their live projects for 12 months FREE of charge.
          </div>
        </div>
      </div>

      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
          <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
            Certification details
          </div>

          <div>
            <div className="flex">
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "objectives" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("objectives");
                }}
              >
                Requirements
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "audience" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("audience");
                }}
              >
                Audience
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "contents" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("contents");
                }}
              >
                Contents
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "exam" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("exam");
                }}
              >
                Award
              </div>
            </div>
            <div className=" bg-white p-2 md:p-6 rounded-bl-md rounded-br-md">
              {menuItem === "objectives" && objectives}
              {menuItem === "audience" && audience}
              {menuItem === "contents" && contents}
              {menuItem === "exam" && exam}
            </div>
          </div>
          <div className="text-center mt-5">
            {/* <Link
                to={{
                  pathname: "https://lnkd.in/ehgBNeDV",
                }}
                target="_blank"
                className="inline-block border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
              >
                Get started now
              </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default MscSoftwareTesting;
