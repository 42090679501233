import React from "react";

function PricingHero(){
    return(
        <div id="top" className="w-full h-full pt-24  bg-pri-500">
            <div className="container flex items-center justify-center  h-full px-3 ">
                <div className="text-center  text-white mt-6">
                    <div className="pri-font hero-text tracking-wide leading-tight  max-w-lg py-10">
                    Choose the perfect plan for your needs
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PricingHero