import React from "react";
import alun from "./../../assets/images/alun.jpg";
import aras from "./../../assets/images/aras_new.png";
import hari from "./../../assets/images/hari.jpg";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

function About(){
    return(
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 ">
            <div className="container">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6"> Directors
                </div>
                <div className="flex flex-col gap-6 justify-center md:flex-row">
                    <div className="flex flex-1 flex-col items-center  ">
                    <div className="w-32 h-32 overflow-hidden flex item-center justify-center mb-3" style={{borderRadius:"50%"}}>
                            <img src={alun} alt=""/>
                        </div>
                        <div className="text-center">
                            <div className="text-xl   pri-font mb-1 text-pri-500">
                            Alun Jones QC
                            
                            </div>
                            <div className="text-xs md:text-sm text-gray-500 ">Chairman</div>
                            <div className="mt-2 inline-block bg-pri-500 hover:bg-sec-500 cursor-pointer" style={{padding:"1px 5px"}}>
                            <a href="https://www.linkedin.com/in/alunjonesqc/" target="_blank">
                            <FeatherIcon
                            icon="linkedin"
                            className="inline  w-5 h-5 text-white "
                            /></a>
                            </div>


                            <Link className="block md:text-lg hover:text-sec-500" to={{ pathname: "mailto:alun.jones@testenium.com" }} target="_blank">alun.jones@testenium.com</Link>
                            
                            <div className=" text-sm">Areas: Public Law, International Business Crime, Extradition, Data Protection Law</div>
                        </div>
                    </div>
                    
                    <div className="flex flex-1 flex-col items-center ">
                    <div className="w-32 h-32 overflow-hidden  mb-3" style={{borderRadius:"50%"}}>
                            <img src={aras} alt="" />
                        </div>
                        <div className="text-center">
                            <div className="text-xl   pri-font mb-1 text-pri-500">
                            Dr Aras Arasilango BSc (Hons), PgD, PhD
                            
                            </div>
                            <div className="text-xs md:text-sm text-gray-500">Chief Executive Officer (CEO) / Founder</div>
                            <div className="mt-2 inline-block bg-pri-500 hover:bg-sec-500 cursor-pointer" style={{padding:"1px 5px"}}>
                            <a href="https://www.linkedin.com/in/dr-aras-arasilango-36622321/" target="_blank">
                            <FeatherIcon
                            icon="linkedin"
                            className="inline  w-5 h-5 text-white "
                            /></a>
                            </div>
                            <Link className="block md:text-lg hover:text-sec-500" to={{ pathname: "mailto:ceo@testenium.com" }} target="_blank">ceo@testenium.com</Link>
                            
                            <div className=" text-sm">Areas: Artificial Intelligence, Cyber Security, Data Science, Machine Learning, Deep Learning, QA, BI, C#, JAVA, Python, PHP</div>
                        </div>
                    </div>
                    <div className="flex flex-1 flex-col items-center  ">
                        <div className="w-32 h-32 overflow-hidden  mb-3" style={{borderRadius:"50%"}}>
                            <img src={hari} alt="" />
                        </div>
                        <div className="text-center">
                            <div className="text-xl   pri-font mb-1 text-pri-500">
                            Harikumar Santhibhavan
                            
                            </div>
                            <div className="text-xs md:text-sm text-gray-500">Chief Technology Officer</div>
                            <span className="mt-2 inline-block bg-pri-500 hover:bg-sec-500 cursor-pointer" style={{padding:"1px 5px"}}>
                            <a href="https://www.linkedin.com/in/svhari/" target="_blank">
                            <FeatherIcon
                            icon="linkedin"
                            className="inline  w-5 h-5 text-white "
                            /></a>
                            </span>
                            <Link className="block md:text-lg hover:text-sec-500" to={{ pathname: "mailto:cto@testenium.com" }} target="_blank">cto@testenium.com</Link>
           
                            <div className=" text-sm">Areas: 5G Cloud Native Development, Microservices, DevOps, Test Automation, AI-ML, Action centered leadership, Program management, Innovation & Change Management.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}
export default About