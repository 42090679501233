import React from "react";
import one from "../../assets/images/customers/hsenid.png";
import two from "../../assets/images/customers/mobileum.png";
import three from "../../assets/images/customers/wenso.png";

const Customers = (props) => {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6 text-pri-500">
          Trusted by many businesses
        </div>
      </div>
      <div className="flex justify-between max-w-5xl mx-auto">
        <div>
          <img className={"h-10 md:h-20 lg:h-24 mx-auto"} src={one} alt={"hsenid"} />
        </div>
        <div>
          <img className={"h-10 md:h-20 lg:h-24 mx-auto"} src={two} alt={"mobileum"} />
        </div>
        <div>
          <img className={"h-10 md:h-20 lg:h-24 mx-auto"} src={three} alt={"wenso"} />
        </div>
      </div>
    </div>
  );
};

export default Customers;
