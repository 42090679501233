import React from "react";

function Careers() {
  return (
    <>
      <div
        className="py-8 sm:py-10 md:py-12 lg:py-16 px-3 bg-pri-500"
        id="careers"
      >
        <div className="container">
          <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font text-white mb-3">
            Robot framework testing with code generation comes FREE with
            <span className="text-sec-500"> Selenium</span> test automation
          </div>
          <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl text-center pri-font text-white">
            Just upload the test plan in excel file
            <span className="text-sec-500">
              {" "}
              TESTENIUM will handle the rest
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Careers;
