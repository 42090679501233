import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

function DipCourses() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-5">
          Diploma courses
        </div>
        <div className="text-lg sm:text-xl md:text-2xl text-center mb-8">
          Certification is offered for following programmes
        </div>
        <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className=" rounded-md " style={{ border: "1px solid #cbd5e0" }}>
            <div className="p-3 lg:p-5">
              <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">
                Postgraduate Diploma in Meta-Automation (Online)
              </div>
              {/* <div className="text-center mb-2 font-semibold">
                Fee{" "}
                <span className="text-3xl md:text-4xl font-medium">£9000</span>
              </div> */}
              <div className="text-center mb-2 font-semibold">
                Duration:
                <span className="text-base font-semibold"> 9 months</span>
              </div>

              <div className=" text-sm sm:text-base mb-1 text-justify">
                Meta automation is simply a concept of "Automating the
                Automation". If there is a platform like TESTENIUM.COM which has
                been rated as the most innovative automation platform, you do
                not need to write the code for...
              </div>

              <Link
                className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500"
                style={{ width: "fit-content" }}
                to="/dip-meta-automation"
              >
                Learn more
                <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
              </Link>

              <div className="flex justify-center mt-5">
                <Link
                  to="/dip-meta-automation"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  View the certification
                </Link>
              </div>
            </div>
          </div>
          <div className=" rounded-md " style={{ border: "1px solid #cbd5e0" }}>
            <div className="p-3 lg:p-5">
              <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">
                Postgraduate Diploma in Blockchain Meta Automation Testing
                (Online)
              </div>
              {/* <div className="text-center mb-2 font-semibold">
                Fee{" "}
                <span className="text-3xl md:text-4xl font-medium">£9000</span>
              </div> */}
              <div className="text-center mb-2 font-semibold">
                Duration:
                <span className="text-base font-semibold"> 9 months</span>
              </div>

              <div className=" text-sm sm:text-base mb-1 text-justify">
                The industry is spending almost $40 billion in writing test
                automation script including Blockchain testing. On top of this,
                the companies have to spend money on heavy hardware to execute
                the test and to install all the tools. These expenses are not...
              </div>

              <Link
                className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500"
                style={{ width: "fit-content" }}
                to="/dip-blockchain-meta-automation-testing"
              >
                Learn more
                <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
              </Link>

              <div className="flex justify-center mt-5">
                <Link
                  to="/dip-blockchain-meta-automation-testing"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  View the certification
                </Link>
              </div>
            </div>
          </div>
          <div className=" rounded-md " style={{ border: "1px solid #cbd5e0" }}>
            <div className="p-3 lg:p-5">
              <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">
                Postgraduate Diploma in Meta Medical Informatics (Online)
              </div>
              {/* <div className="text-center mb-2 font-semibold">
                Fee{" "}
                <span className="text-3xl md:text-4xl font-medium">£9000</span>
              </div> */}
              <div className="text-center mb-2 font-semibold">
                Duration:
                <span className="text-base font-semibold"> 9 months</span>
              </div>

              <div className=" text-sm sm:text-base mb-1 text-justify">
                Big data analytics has changed how we manage, analyze, and
                leverage data across industries. One of the most notable sectors
                where data analytics is making significant changes is Medicine.
                When use Meta Automation, data analytics...
              </div>

              <Link
                className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500"
                style={{ width: "fit-content" }}
                to="/dip-meta-medical-informatics"
              >
                Learn more
                <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
              </Link>

              <div className="flex justify-center mt-5">
                <Link
                  to="/dip-meta-medical-informatics"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  View the certification
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DipCourses;
