import React, { useState } from "react";
import { Collapse } from 'antd';
const { Panel } = Collapse;

function TestAutomationScale() {
    const [type, setType] = useState("codelessTestAutomation")
    

    const codelessTestAutomation = (
        <>
            <div className="text-sm md:text-xl pri-font pb-2">
                The Problems with Codeless Test Automation
            </div>
            <div className="text-sm md:text-lg  font-semibold">
                Lack of Customization:
            </div>
            <div className="text-sm sm:text-base  text-justify">
                The scripts are not visible to the user and the automation is processed in the background, leaving no opportunity for testers to modify the scripts.
            </div>
            <div className="text-sm md:text-lg  font-semibold mt-2">
                Codeless is Misleading:
            </div>
            <div className="text-sm sm:text-base  text-justify">
                Although scripts are generated and structured internally, additional implementaion of framework will require some manual coding. But it is impossible to modify the code as it is hidden.
            </div>
            <div className="text-sm md:text-lg  font-semibold mt-2">
                Lack of Maintainability:
            </div>
            <div className="text-sm sm:text-base  text-justify">
                If test projects weren't designed to be reusable, problems could arise in maintainability as the application is continually changing and needs to be tested repeatedly.        </div>
            <div className="text-sm md:text-lg  font-semibold mt-2">
                Platform Dependency:
            </div>
            <div className="text-sm sm:text-base  text-justify">
                Codeless test automation solutions are not universal, with either platform dependency.
            </div>
        </>
    )

    const recordAndPlay = (
        <>
            <div className="text-sm md:text-xl pri-font pb-2">
                The Problems with Record-and-Play
            </div>
            <div className="text-sm sm:text-base  text-justify">
                Recording and Playing for test automation is not a good practice for software test automation and has the following problems.
            </div>
            <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
                <li>A link will not fail even if you click a wrong LinkText. In Testenium you will provide all the expected values.</li>
                <li>Record and playback tests break often, but Testenium execution never breaks.</li>
                <li>Often duplicate lines of code, but Testenium generates unique code.</li>
                <li>Harder to debug failing scripts, but no need of debugging in Testenium as it generates 100% accurate code.</li>
                <li>Files created are extremely large, they execute slowly, but testenium creates generic modules and shared by other classes.</li>
            </ul>
        </>

    )

    const manualTesting = (
        <>
            <div className="text-sm md:text-xl pri-font pb-2">
                The Problems with Manual Testing
            </div>
            <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
                <li>Human cannot compete the speed of the machine and therefore needs more manpower and very expensive</li>
                <li>Human cannot check and validate the components correctly.</li>
                <li>Human makes more mistakes, but Testenium generates 100% accurate code faster.</li>
                <li>Company needs more manual testers compared to using Testenium, because Testenium generates code within seconds.</li>
            </ul>
        </>
    )
    const manualCoding = (
        <>
            <div className="text-sm md:text-xl pri-font pb-2">
                The Problems with Manual Coding
            </div>
            <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
                <li>Human cannot write the code faster than computers.</li>
                <li>Human makes mistakes in coding, but Testenium generates 100% accurate code faster</li>
                <li>User needs to install software and testing tools for manual coding, but no tools necessary with Testenium.</li>
                <li>User needs powerful computers to execute test projects, Testenium executes on scalable cloud server.</li>
                <li>Creating projects, adding dependencies and writing code is time consuming, hence expensive. Testenium does all these automatically .</li>
            </ul>
        </>
    )




    return (
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
            <div className="container">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
                    Test Automation at Scale
                </div>
                <div className="text-sm sm:text-base md:text-lg text-justify pb-5">
                    As per https://www.marketsandmarkets.com/, the global Automation testing market size is expected to grow USD 20.7 billion in 2021 to USD 49.9 billion by 2026, at a Compound Annual Growth Rate (CAGR) of 19.2% during the forecast period. The Automation testing market is growing due to the rapid adoption of advanced technologies. </div>
                <div className="text-lg sm:text-xl md:text-2xl  pri-font mb-6"> Problems with...</div>

                <div className="hidden md:grid   gap-4" style={{gridTemplateColumns: "1fr 2fr"}}>
                    <div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-6 rounded-md  hover:bg-white " 
                        + (type === "codelessTestAutomation" && " bg-white shadow-md")}
                            onClick={() => {
                                setType("codelessTestAutomation");

                            }}
                        >Codeless Test Automation</div>
                        
                        <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-6 rounded-md mt-4 hover:bg-white " 
                        + (type === "recordAndPlay" && " bg-white shadow-md")}
                            onClick={() => {
                                setType("recordAndPlay");
                        
                            }}
                        >Record-and-Play</div>
                        
                        <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-6 rounded-md mt-4 hover:bg-white " 
                        + (type === "manualTesting" && " bg-white shadow-md")}
                            onClick={() => {
                                setType("manualTesting");
                            }}
                        >Manual Testing</div>
                        
                        <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-6 rounded-md mt-4 hover:bg-white " 
                        + (type === "manualCoding" && " bg-white shadow-md")}
                            onClick={() => {
                                setType("manualCoding");
                            }}
                        >Manual Coding
                        </div>
                        
                    </div>

                    <div className=" bg-white p-6 rounded-md">
                        {type === "codelessTestAutomation" && codelessTestAutomation}
                        {type === "recordAndPlay" && recordAndPlay}
                        {type === "manualTesting" && manualTesting}
                        {type === "manualCoding" && manualCoding}
                    </div>
                </div>

                        <div className="block md:hidden">
                        <Collapse accordion bordered={false} defaultActiveKey={['1']}>
                            <Panel className="text-lg sm:text-xl md:text-2xl cursor-pointer  " header="Codeless Test Automation" key="1">
                            {codelessTestAutomation}
                            </Panel>
                            <Panel className="text-lg sm:text-xl md:text-2xl cursor-pointer  " header="Record And Play" key="2">
                            {recordAndPlay}
                            </Panel>
                            <Panel className="text-lg sm:text-xl md:text-2xl cursor-pointer  " header="Manual Testing" key="3">
                            {manualTesting}
                            </Panel>
                            <Panel className="text-lg sm:text-xl md:text-2xl cursor-pointer  " header="Manual Coding" key="4">
                            {manualCoding}
                            </Panel>
                        </Collapse>
                        </div>    

            </div>
        </div>
    )
}

export default TestAutomationScale