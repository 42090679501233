import React from "react";
import Footer from "../../components/web/Footer";
import SEPDeveloper from "../../components/web/SEPDeveloper";


function SEPDevCert(){
    return(
        <div>
            <div className="h-full">
       <SEPDeveloper/>
        <Footer/>
        </div>
        </div>
    )
    
}

export default SEPDevCert