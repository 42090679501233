import React from "react";
import webantrix from "./../../assets/images/partners/webantrix.png";

function StrategicPartners() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-8">
          Strategic Partners
        </div>
        <div className="flex items-center">
          <img
            className={"h-8 md:h-14 lg:h-24 mx-auto"}
            src={webantrix}
            alt={"Webantrix"}
          />
        </div>
      </div>
    </div>
  );
}

export default StrategicPartners;
