import React from "react"
import {Modal} from "antd"

function TestingMoreModal(props){
    const crossBrowserTestAutomation = (
        <>
        <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Cross Browser Test Automation
      </div>
      <div className="text-base mb-3">
      Testenium is the first and only Meta-computing based QAOps/testing platform in the world that provides online infrastructure to create test project, generate code and implements steps in BDD (Behavior Driven Development) and Page Object Model for Microsoft Playwright, Cypress and Selenium test automation in C#, Java, Python, JavaScript and TypeScript. Testenium supports 100% online test databases (Oracle and SQL Server). With Testenium you can test your code without any testing tools or programming language compilers installed in your computer. You can even use iPad, Tablet or Smartphones to generate code for BDD.            </div>
      <div className="text-base mb-3">
      For Playwright, Cypress, BDD and Selenium test automation, you do not have to install and configure any testing tools in your computer. All the tasks such as creating the projects, editing projects, adding dependencies, access to database, executing the test and creating reports are done in the Testenium server.
      </div>
          
      </> 
    )

    return(
        <Modal
            visible={props.visible}
            onCancel={()=>props.handleCancel()}
            footer={null}
            width={600}
        >
            {props.more === "crossBrowserTestAutomation" && crossBrowserTestAutomation}
        </Modal>
    )
}
export default TestingMoreModal