import React, { useState } from "react";
// import { SendMailAWS } from "../../api/SendEmail";

import bdd from "./../../assets/images/BDD.png";
import sel from "./../../assets/images/SEL.png";
import ami from "./../../assets/images/AMI.png";
import ses from "./../../assets/images/SES.png";
import FeatherIcon from "feather-icons-react";
import FeaturesMoreModal from "../Modals/FeaturesMoreModal";

function Features(props) {
  const [more, setMore] = useState(false);
  const [moreOf, setMoreOf] = useState("1");

  return (
    <>
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
        <div className="container">
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl pri-font text-pri-900 mb-3">
                Voice Activated BDD
              </div>
              <div className="text-sm sm:text-base md:text-lg mb-6 max-w-5xl mx-auto">
                Generate the feature file by Speech to Code technique. Test the
                UI of the applications without installing any testing tools or
                writing any code. You just need a browser on your device.
                Prepare a BDD feature file with the URL and the elements'
                details such as...
              </div>
              <div className="">
                <div className="text-base text-sec-500 cursor-pointer hover:text-pri-500 inline">
                  <div
                    onClick={() => {
                      setMore(true);
                      setMoreOf("1");
                    }}
                    className="flex items-center"
                  >
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:block flex-1">
              <div className="mx-auto max-w-md overflow-hidden" >
                <img className="" src={bdd} alt="Web app development" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*-----------------------------------------------------*/}
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
          <div className="flex items-center gap-4">
            <div className="hidden md:block flex-1">
              <div className=" mx-auto max-w-md overflow-hidden">
                <img src={sel} alt="Web app development" />
              </div>
            </div>
            <div className="flex-1">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl pri-font text-pri-900 mb-3">
                Selenium UI Test Automation without Tools
              </div>
              <div className="text-sm sm:text-base md:text-lg mb-6 max-w-5xl mx-auto">
                Test the UI of the application without installing any testing
                tools. Prepare an Excel file with elements' details such as
                locator, function and test data for every page in separate excel
                sheet. Provide the URL on the Testenium UI and upload the Excel
                file. Testenium generates code...
              </div>
              <div className="">
                <div className="text-base text-sec-500 cursor-pointer hover:text-pri-500 inline">
                  <div
                    onClick={() => {
                      setMore(true);
                      setMoreOf("2");
                    }}
                    className="flex items-center"
                  >
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*-----------------------------------------------------*/}
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
        <div className="container">
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl pri-font text-pri-900 mb-3">
                Automatic Method Implementation with BDD
              </div>
              <div className="text-sm sm:text-base md:text-lg mb-6 max-w-5xl mx-auto">
                Test the UI of the applications without installing any testing
                tools or Excel. You just need a browser on your device. Prepare
                a BDD feature file with the URL and the elements' details such
                as locator, function using Gherkin language. Testenium generates
                code, implements all...
              </div>
              <div className="">
                <div className="text-base text-sec-500 cursor-pointer hover:text-pri-500 inline">
                  <div
                    onClick={() => {
                      setMore(true);
                      setMoreOf("3");
                    }}
                    className="flex items-center"
                  >
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:block flex-1">
              <div className=" mx-auto max-w-md overflow-hidden">
                <img src={ami} alt="Web app development" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*-----------------------------------------------------*/}
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
          <div className="flex items-center gap-4">
            <div className="hidden md:block flex-1">
              <div className=" mx-auto max-w-md overflow-hidden">
                <img src={ses} alt="Web app development" />
              </div>
            </div>
            <div className="flex-1">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl pri-font text-pri-900 mb-3">
                Encryption without Key-Management
              </div>
              <div className="text-sm sm:text-base md:text-lg mb-6 max-w-5xl mx-auto">
                Secure your sensitive documents easily with our next generation
                document encryption system. You do not have to maintain the keys
                at all as the unique keys are embedded in the software already
                for every user...
              </div>
              <div className="">
                <div className="text-base text-sec-500 cursor-pointer hover:text-pri-500 inline">
                  <div
                    onClick={() => {
                      setMore(true);
                      setMoreOf("4");
                    }}
                    className="flex items-center"
                  >
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*-----------------------------------------------------*/}
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
        <div className="container">
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl pri-font text-pri-900 mb-3">
                Secure Encryption & Cloud Storage
              </div>
              <div className="text-sm sm:text-base md:text-lg mb-6 max-w-5xl mx-auto">
                Users can also upload any sensitive documents to Testenium cloud
                platform to encrypt or decrypt them very easily. Up on uploading
                the documents, Testenium encrypts the document and allows the
                user to download it or keep it in the cloud so that it can be
                downloaded...
              </div>
              <div className="">
                <div className="text-base text-sec-500 cursor-pointer hover:text-pri-500 inline">
                  <div
                    onClick={() => {
                      setMore(true);
                      setMoreOf("5");
                    }}
                    className="flex items-center"
                  >
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:block flex-1">
              <div className=" mx-auto max-w-md overflow-hidden">
                <img src={ses} alt="Web app development" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeaturesMoreModal
        visible={more}
        handleCancel={() => setMore(false)}
        more={moreOf}
      />
    </>
  );
}

export default Features;
