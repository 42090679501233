import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-scroll";

function HeroScrollBtn() {
  return (
    <Link
      activeClass=""
      to="services"
      spy={true}
      smooth={true}
      duration={500}
      className="border-sec-500 border-2 mx-auto rounded-full flex items-center justify-center h-12 w-12 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white hero-down"
    >
      <FeatherIcon className="w-5 h-5" icon="chevrons-down" />
    </Link>
  );
}

export default HeroScrollBtn;
