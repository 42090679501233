import React from "react";
import one from "./../../assets/images/travancore.png";
import two from "./../../assets/images/mediatronix.png";
import { Link } from "react-router-dom";

function TechPartners() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-8">
          Technology Partners
        </div>
        <div className="flex items-center sm:max-w-lg md:max-w-2xl  mx-auto">
          <Link
            to={{
              pathname: "https://www.travancoreanalytics.com/",
            }}
            target="_blank"
            className="mx-auto "
          >
            <img
              className={"h-6 md:h-12 lg:h-16 mx-auto "}
              src={one}
              alt={"travancore"}
            />
          </Link>
          <Link
            to={{
              pathname: "https://mtxglobal.com/",
            }}
            target="_blank"
            className="mx-auto "
          >
            <img
              className={"h-6 md:h-12 lg:h-16 mx-auto"}
              src={two}
              alt={"mediatronics"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TechPartners;
