import React from "react";
import Slider from "react-slick";

function Testimonials() {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6 text-pri-500">
          Listen to our customers
        </div>
        <div className="text-sm sm:text-base md:text-lg text-justify mb-6 max-w-2xl mx-auto">
          Our customer list ranges from small start-ups to big enterprises. Our
          development experience comes from working extensively with various
          industry focus businesses.
        </div>
        <Slider {...settings}>
          <div className="mb-2">
            <div className="text-sm sm:text-base text-justify mb-6 max-w-2xl mx-auto">
              Testenium is one of the best test automation tool that we can use
              for various automation activities within one platform. Either
              functional or non-functional testing you are expecting to perform,
              it will be covered if you have Testenium. It is a far better,
              efficient tool for Automation testing I have ever seen. Our team
              started using Testenium a few months back, and the results are
              amazing..
            </div>
            <div className="text-center md:text-lg text-sec-500">Saraji Dhanushka</div>
            <div className="text-center text-xs md:text-sm text-gray-500">
              Associate QA Lead, hSenid Business Solutions
            </div>
          </div>
          <div className="mb-2">
            <div className="text-sm sm:text-base text-justify mb-6 max-w-2xl mx-auto">
              Testenium is an effective online automation platform for testing
              that can be used to login from a web browser without using other
              tools. I think using testenium we can reduce the time for writing
              the automation scripts manually and can do the execution faster
              than before. So I think it's a very effective and useful online
              automation platform for testing ever. Also I have worked with Dr
              Aras Arasilango quite some time and he is very helpful and an
              amazing person to work with.
            </div>
            <div className="text-center md:text-lg text-sec-500">Uwini Nimaya</div>
            <div className="text-center text-xs md:text-sm text-gray-500">
              Quality Assurance Engineer, hSenid Business Solutions
            </div>
          </div>
          <div className="mb-2">
            <div className="text-sm sm:text-base text-justify mb-6 max-w-2xl mx-auto">
              I would like to say that testenium is the most innovative
              automation platform in the market so far. It lets testers do all
              kinds of testing at one place. Before using Testenium we had to
              spend more time on setting up the environment, coding, executing.
              Testenium makes our lives easier. It saves most of our time on
              unnecessary work. I must mention the dedication of Dr. Aras. When
              we have an issue regarding the tool or any other testing issues,
              he connects with us without any delay and solves the problem. So I
              want to thank him in advance for his support and making our
              testing life easier.
            </div>
            <div className="text-center md:text-lg text-sec-500">Hirushi Sedara</div>
            <div className="text-center text-xs md:text-sm text-gray-500">
              Quality Assurance Engineer, hSenid Business Solutions
            </div>
          </div>
          <div className="mb-2">
            <div className="text-sm sm:text-base text-justify mb-6 max-w-2xl mx-auto">
              The Testenium is a good tool to test web Applications via Online
              Platform. Doesn't need any additional installations to add up to
              the computer system like any other Testing software tools. The
              Most advantageous part in the Testenium tool is API testing. It is
              the easiest API testing tool compared to the any other API tools I
              used to be worked with. And Selenium testing is also very quick
              and easy, compared to any other softwares. It is reducing lots of
              time for scripting and executions rather than before. Can
              recommend this online platform Automation tool for anyone.
            </div>
            <div className="text-center md:text-lg text-sec-500">Hansani Gunasekara</div>
            <div className="text-center text-xs md:text-sm text-gray-500">
              Associate Quality Assurance Engineer, hSenid Business Solutions
            </div>
          </div>
          {/* <div className="mb-2">
            <div className="mb-6">
              <div className="w-20 h-20 rounded-full bg-pri-500 mx-auto overflow-hidden">
                <img
                  className="w-20 h-20 rounded-full"
                  src={prof}
                  alt="Profile"
                />
              </div>
            </div>
            <div className="text-sm sm:text-base md:text-lg text-center mb-6 max-w-2xl mx-auto">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </div>
            <div className="text-center md:text-lg">John Doe</div>
            <div className="text-center text-xs md:text-sm text-gray-500">
              Managing Director, Kodplex
            </div>
          </div> */}
        </Slider>
        {/* <Customers /> */}
      </div>
    </div>
  );
}

export default Testimonials;
