import React from "react";
import Slider from "react-slick";

function AboutUstestimonials(){
    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        cssEase: "linear",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

      return (
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
          <div className="container">
            <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6 text-pri-500">
              Listen to our customers
            </div>
            
            <Slider {...settings}>
              <div className="mb-2">
                <div className="text-sm sm:text-base text-justify mb-6 max-w-2xl mx-auto">
                Testenium is one of the unique products which has helped my company to complete the testing project fast and effective in finding the Quality of the Project. Generating the test script which is faster, effective and reliable to be used in the projects. It is far better than product available in the market; which generate the automation script as faster as a light. we can also integrate Testenium with third party tools. Overall the performance of the product was good and can be used for generating the scripts and also gives report in the form of Video and Screenshot.
                </div>
                <div className="text-center md:text-lg text-sec-500">Mr S Bharadwaj</div>
                <div className="text-center text-xs md:text-sm text-gray-500">
                SRS Software, India
                </div>
              </div>
              <div className="mb-2">
                <div className="text-sm sm:text-base text-justify mb-6 max-w-2xl mx-auto">
                Testenium is a revolutionary platform saving you both time and money. It's clean and simple interface, coupled with a neat format enables efficient test automation in multiple programming languages. I do not hesitate to recommend this service.
                </div>
                <div className="text-center md:text-lg text-sec-500">Mr Aiden Long</div>
                <div className="text-center text-xs md:text-sm text-gray-500">
                CAPITA, UK
                </div>
              </div>
              <div className="mb-2">
                <div className="text-sm sm:text-base text-justify mb-6 max-w-2xl mx-auto">
                I use Testenium for BDD and Selenium testing. It also provide online database facilities which redundant the need for a DB system in a server or your local machine. I recommend Testenium for users, companies who would like to practice BDD but do not want to go through the hassle of installing or investing on software application.
                </div>
                <div className="text-center md:text-lg text-sec-500">Mr Sadiq Rahim</div>
                <div className="text-center text-xs md:text-sm text-gray-500">
                American Express, UK
                </div>
              </div>             
            </Slider>
           
          </div>
        </div>
      );
}

export default AboutUstestimonials