import React from "react";

function TamilMetaProgramming(){
    return(
        <div
        className="py-8 sm:py-10 md:py-12 lg:py-16 px-3 bg-pri-500" >
        <div className="container">
          <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font text-white mb-3">
            Protect your data with <span className="text-sec-500"> Searchable Encryption </span>
            
          </div>
          <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl text-center pri-font text-white">
          Testenium provides <span className="text-sec-500"> Tamil meta programming </span> 
            language to develop 100% secure searchable encrypted database application
          </div>
        </div>
      </div>
    )
}

export default  TamilMetaProgramming;