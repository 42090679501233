import React from "react";
import { Link } from "react-router-dom";

function Process() {
  return (
    <div
      className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200"
      id="process"
    >
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
          Functional & Non Functional Testing made simple
        </div>
        <div className="text-lg sm:text-xl md:text-2xl text-center pb-2">
          Test your applications effortlessly using Hybrid Meta Automation Cloud
          Platform
        </div>
        <div className="text-sm sm:text-base md:text-lg text-center pb-5">
          Gives testing teams of our customers end to end seamless experience by
          generating millions of lines of code within a second
        </div>
        <div className="text-lg sm:text-xl md:text-2xl text-center pb-2">
          You do not need to install any testing tool or write a single line of
          code
        </div>
        <div className="text-sm sm:text-base md:text-lg text-center pb-10">
          Pay as you Test - monthly subcription depends on the country, starting
          from just £49.00 per month.
        </div>
        <div className="text-center ">
          <Link
            to={{
              pathname:
                "https://testenium.co.uk/testenium/app/signup.php?amt=0&opt=trial",
            }}
            target="_blank"
            className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
          >
            Get started now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Process;
