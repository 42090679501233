import React, { useEffect } from "react";

import { withRouter } from "react-router-dom";
import TopBar from "../components/web/TopBar";
import NavBar from "../components/web/NavBar";

function Web(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <div className="h-full">
      <TopBar />
      <NavBar />
      {props.children}
    </div>
  );
}

export default withRouter(Web);
