import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

function ProtectingDocs() {
  return (
    <>
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
          <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font ">
            Protecting Sensitive Documents
          </div>
          <div className="text-lg sm:text-xl md:text-2xl text-center mb-8">
            FREE for first month
          </div>

          <div className="text-sm sm:text-base text-justify mb-6  mx-auto">
            Confidential Document Security is a major issue faced by many
            businesses operating worldwide. When documents are uploaded by a
            company onto the internet through cloud storage devices and
            platforms, they are at an extremely high risk of falling into
            malicious viruses and dangerous hackers. Government Departments,
            such as NHS and Councils also face the same problems due to lack of
            security, wrong practices and experties related to encryption.
            Testenium provides a next generation excryption scheme to encrypt
            confidential documents or files and store them on cloud storage or
            download software to encrypt/decrypt a series of files in a folder
            locally without programming or managing the encryption keys
          </div>
          <div className="text-sm sm:text-base text-justify font-semibold mb-10  mx-auto">
            We offer concession to government departments, universities and
            students around the world. Please Contact us for the pricing.
          </div>
          <div className="text-center ">
            <Link
              to={{
                pathname:
                  "https://testenium.co.uk/testenium/app/contact_form.php",
              }}
              target="_blank"
              className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
            >
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 ">
        <div className="container">
          <div className="grid  md:grid-cols-3 gap-4  ">
            <div className=" rounded-md border-2 border-pri-500">
              {/* <div className="text-sm sm:text-base md:text-lg bg-sec-500 p-3 rounded-tr-md rounded-tl-md text-white">FREE for first month</div> */}
              <div className="p-3 lg:p-5">
                <div className="text-lg md:h-16 sm:text-xl md:text-2xl text-center pri-font ">
                  Document Encryption
                </div>
                {/* <div className="text-center mb-2">
                  <span className="text-4xl md:text-5xl">£49</span> /month
                </div> */}
                <div className="mt-6  mb-8 text-center">
                  <Link
                    to={{
                      pathname:
                        "https://testenium.co.uk/testenium/app/contact_form.php",
                    }}
                    target="_blank"
                    className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                  >
                    Contact us
                  </Link>
                </div>
                <div className="flex items-center text-sm sm:text-base mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Get One Hour Training</div>
                </div>
                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Create Project Automatic</div>
                </div>
                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Upload Document</div>
                </div>
                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Encrypt Document</div>
                </div>

                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Store on Cloud</div>
                </div>
                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Document Management</div>
                </div>
              </div>
            </div>
            <div className=" rounded-md border-2 border-pri-500">
              {/* <div className="text-sm sm:text-base md:text-lg bg-sec-500 p-3 rounded-tr-md rounded-tl-md text-white">FREE for first month</div> */}
              <div className="p-3 lg:p-5 ">
                <div className="text-lg md:h-16 sm:text-xl md:text-2xl text-center pri-font ">
                  Download Encryption Software
                </div>
                {/* <div className="text-center mb-2">
                  <span className="text-4xl md:text-5xl">£49</span> /month
                </div> */}
                <div className="mt-6  mb-8 text-center">
                  <Link
                    to={{
                      pathname:
                        "https://testenium.co.uk/testenium/app/contact_form.php",
                    }}
                    target="_blank"
                    className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                  >
                    Contact us
                  </Link>
                </div>
                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div></div>
                  Get One Hour Training
                </div>
                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Create Project Automatic</div>
                </div>
                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Encrypt/Decrypt Files</div>
                </div>
              </div>
            </div>
            <div className="rounded-md border-2 border-pri-500">
              {/* <div className="text-sm sm:text-base md:text-lg bg-sec-500 p-3 rounded-tr-md rounded-tl-md text-white">FREE for first month</div> */}
              <div className="p-3 lg:p-5">
                <div className="text-lg md:h-16 sm:text-xl md:text-2xl text-center pri-font ">
                  Document Encryption & Download Software
                </div>
                {/* <div className="text-center mb-2">
                  <span className="text-4xl md:text-5xl">£69</span> /month
                </div> */}
                <div className="mt-6 mb-8 text-center">
                  <Link
                    to={{
                      pathname:
                        "https://testenium.co.uk/testenium/app/contact_form.php",
                    }}
                    target="_blank"
                    className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                  >
                    Contact us
                  </Link>
                </div>
                <div className="flex items-center text-sm sm:text-base mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Get One Hour Training</div>
                </div>
                <div className="flex items-center text-sm sm:text-base mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Create Project Automatic</div>
                </div>
                <div className="flex items-center text-sm sm:text-base mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Upload Document</div>
                </div>
                <div className="flex items-center text-sm sm:text-base mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Encrypt Document</div>
                </div>
                <div className="flex items-center text-sm sm:text-base mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Store on Cloud</div>
                </div>
                <div className="flex items-center text-sm sm:text-base mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Download software</div>
                </div>
                <div className="flex items-center text-sm sm:text-base mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Encrypt/Decrypt Files</div>
                </div>
                <div className="flex items-center text-sm sm:text-base  mb-1">
                  <FeatherIcon
                    className="text-sec-500 w-5 h-5 inline mr-1"
                    style={{ marginTop: "2px" }}
                    icon="check"
                  />
                  <div>Document Management</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProtectingDocs;
