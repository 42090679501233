import React from "react";
import FeatherIcon from "feather-icons-react";

function TopBar() {
  return (
    <div className="bg-pri-300 px-3 py-2 absolute w-full">
      <div className="container">
        <div className="flex items-center justify-center sm:justify-between">
          <div className="text-white text-sm"></div>
          <div className="flex">
            <div className="text-xs sm:text-sm text-white items-center sm:mr-6 flex">
              Top company of the year 2020 by CEO Views, USA
            </div>
            <div className="text-sm text-white items-center mr-6  hidden md:flex">
              <FeatherIcon icon="mail" className="text-sec-500 w-4 h-4 mr-1" />
              info@testenium.com
            </div>
            <div className="text-sm text-white items-center hidden sm:flex">
              <FeatherIcon icon="phone" className="text-sec-500 w-4 h-4 mr-1" />
              +44(0)2081549069
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
