import React, { useState } from "react";
import { Link } from "react-router-dom";

function DipBlockchainMetaAutomationc() {
  const [menuItem, setMenuItem] = useState("objectives");

  const objectives = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Prove your skills and knowledge
      </div>
      <div className="text-sm sm:text-base  text-justify mb-2">
        Entry Requirement: BSc in Computer Science or any related topics
      </div>
    </>
  );
  const audience = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Is this certification right for you?
      </div>
      <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
        <li>Test engineers</li>
        <li>Test managers</li>
        <li>Junior testers</li>
      </ul>
    </>
  );
  const contents = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        The best way to learn is to do
      </div>
      <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
        <li>Advanced Meta Programming</li>
        <li>Meta Computing and Software Engineering</li>
        <li>Blockchain Applications Development</li>
        <li>Writing Ethereum Smart contracts</li>
        <li>Continuous Integration</li>
        <li>Migrating to Testnet & Unit Testing</li>
        <li>Smart Contract Code Coverage</li>
        <li>Blockchain Project Management</li>
      </ul>
    </>
  );
  const exam = (
    <>
      <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Receive your recognition
      </div>
      <div className="text-sm sm:text-base  text-justify">
        Postgraduate Diploma Qualification
      </div>
    </>
  );

  return (
    <>
      <div id="top" className="w-full h-full pt-24 px-3 bg-pri-500">
        <div className="container flex items-center   h-full ">
          <div className="  text-white mt-6 py-10">
            <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl  pri-font text-white">
              <Link
                className="text-white hover:text-sec-500 cursor-pointer"
                to="/diploma-courses"
              >
                Diploma courses
              </Link>{" "}
              / Certification
            </div>
            <div className="pri-font hero-text tracking-wide leading-tight max-w-5xl ">
              Postgraduate Diploma in Blockchain Meta Automation Testing
              (Online)
            </div>

            <Link
              to={{
                pathname: "https://lnkd.in/esBtpCnY",
              }}
              target="_blank"
              className="inline-block mt-5 whitespace-nowrap text-sm lg:text-base text-white border-2 border-sec-500 py-2 px-8 rounded-md bg-sec-500 cursor-pointer hover:bg-white hover:text-sec-500"
            >
              Apply now
            </Link>
          </div>
        </div>
      </div>
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
        <div className="container">
          <div className="text-lg sm:text-xl md:text-2xl  pri-font  mb-2">
            Why do I need to learn Blockchain Meta Automation Testing?
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify ">
            The industry is spending almost $40 billion in writing test
            automation script including Blockchain testing. On top of this, the
            companies have to spend money on heavy hardware to execute the test
            and to install all the tools. These expenses are not necessary if
            you go for meta-automation. Meta automation is simply a concept of
            "Automating the Automation". If there is a platform like Testenium
            which has been rated as the most innovative automation platform, you
            do not need to write the code for any automation task. Although
            there are recording tools and no-code/low-code platforms which will
            not support 100% automation and they have a lot of problems. In
            testenium.com you only need to provide the test automation
            requirements by means of giving the elements' details. Testenium.com
            will create project, write the code (100% accurate), execute the
            test on cloud at scale and produce reports, so that you can save
            over 90% of the time in running a test automation project. The
            future is Meta-computing and Meta-automation and the general high
            level programming is going to be obsolete soon with the introduction
            of Meta-computing and meta-programming anyway.
          </div>
          <div className="text-lg sm:text-xl md:text-2xl  pri-font mt-3  mb-2">
            What do I get?
          </div>
          <div className="text-sm sm:text-base md:text-lg text-justify ">
            One Year FREE access to testenium.com Meta-automation Platform with
            this course (worth £8988 - UI Testing [Selenium, Microsoft
            Playwright, Robot Framework, BDD, POB], API Testing, LOAD Testing,
            PENETRATION Testing, COVERAGE, Encryption, Blockchain Testing,
            Online Database, Excel comparision)
          </div>
        </div>
      </div>

      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
        <div className="container">
          <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
            Certification details
          </div>

          <div>
            <div className="flex">
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "objectives" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("objectives");
                }}
              >
                Requirements
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "audience" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("audience");
                }}
              >
                Audience
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "contents" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("contents");
                }}
              >
                Contents
              </div>
              <div
                className={
                  "text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " +
                  (menuItem === "exam" && " bg-white ")
                }
                onClick={() => {
                  setMenuItem("exam");
                }}
              >
                Award
              </div>
            </div>
            <div className=" bg-white p-2 md:p-6 rounded-bl-md rounded-br-md">
              {menuItem === "objectives" && objectives}
              {menuItem === "audience" && audience}
              {menuItem === "contents" && contents}
              {menuItem === "exam" && exam}
            </div>
          </div>
          <div className="text-center mt-5">
            {/* <Link
              to={{
                pathname: "https://lnkd.in/ehgBNeDV",
              }}
              target="_blank"
              className="inline-block border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
            >
              Get started now
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default DipBlockchainMetaAutomationc;
