import React from "react";


function WhyEncryption(){

   return(
       
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
            <div className="container">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
                Why Encryption is Important?
                </div>
                <div className="text-sm sm:text-base md:text-lg text-justify pb-5">
                Personal and sensitive data held by organisations, large and small, public and private, is under constant threat. In the UK, companies that collect and process personal data are regulated by the Information Commissioner’s Office (ICO), who enforces the Data Protection Act 2018 and the EU General Data Protection Regulations (GDPR). All countries are tightening rules for protecting data.
                </div>
                <div className="text-sm sm:text-base md:text-lg text-justify pb-5">
                “Personal data” and “sensitive personal data” are within the GDPR. “Personal data” is a complex category of information, meaning, broadly, information which can be used to identify a person, for this example, his name, address or IP address. “Sensitive personal information” includes, for example genetic data, and information about religious and political views and sexual orientation. Pseudonymised personal data is now included.                </div>
                <div className="text-sm sm:text-base md:text-lg text-justify ">
                It is the duty of data controllers and processors to implement technical and organisational measures proportionate to the threats to the data, and the risks involved in the nature, scope and context of processing. The costs of protection must be balanced against the potential damage to data, and the penalties regulators might impose for beaches of the law. Controllers and processors must be aware of the importance of pseudonymisation and the encryption of data. They must maintain: (1) systems to protect the integrity availability and integrity of processing procedures and service; and (2) mechanisms which ensure the regular testing and evaluation of these systems and their efficiency.                </div>
               
              </div>
            </div>
            
          
    )
}

export default WhyEncryption;