import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

function DegCourses() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
      <div className="container">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-5">
          Degree courses
        </div>
        <div className="text-lg sm:text-xl md:text-2xl text-center mb-8">
          Certification is offered for following programmes
        </div>
        <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className=" rounded-md " style={{ border: "1px solid #cbd5e0" }}>
            <div className="p-3 lg:p-5">
              <div className="text-lg md:h-20 sm:text-xl md:text-2xl text-center pri-font mb-2">
                Executive Master in Software Testing Meta-Automation (Online)
              </div>
              {/* <div className="text-center mb-2 font-semibold">
                Fee{" "}
                <span className="text-3xl md:text-4xl font-medium">£9000</span>
              </div> */}
              <div className="text-center mb-2 font-semibold">
                Duration:
                <span className="text-base font-semibold"> 12 months</span>
              </div>

              <div className=" text-sm sm:text-base mb-1 text-justify">
                Meta automation is simply a concept of "Automating the
                Automation". If there is a platform like TESTENIUM.COM which has
                been rated as the most innovative automation platform, you do
                not need to write the code for...
              </div>

              <Link
                className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500"
                style={{ width: "fit-content" }}
                to="/msc-software-testing"
              >
                Learn more
                <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
              </Link>

              <div className="flex justify-center mt-5">
                <Link
                  to="/msc-software-testing"
                  className="border-sec-500 border-2 rounded-md text-base py-3 px-8 text-sec-500 cursor-pointer hover:bg-sec-500 hover:text-white"
                >
                  View the certification
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DegCourses;
