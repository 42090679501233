import React from "react";
import DipMetaAutomationc from "../../components/web/diplomaCourses/DipMetaAutomationc";
import Footer from "../../components/web/Footer";

function DipMetaAutomation() {
  return (
    <div>
      <div className="h-full">
        <DipMetaAutomationc />
        <Footer />
      </div>
    </div>
  );
}

export default DipMetaAutomation;
