import React from "react"
import {Modal} from "antd"


function EncryptionMoreModal(props){
    const whyEncryption = (
        <>
        <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Why Encryption Important?
      </div>
      <div className="text-base mb-3">
      Personal and sensitive data held by organisations, large and small, public and private, is under constant threat. In the UK, companies that collect and process personal data are regulated by the Information Commissioner’s Office (ICO), who enforces the Data Protection Act 2018 and the EU General Data Protection Regulations (GDPR). All countries are tightening rules for protecting data.
            </div>
      <div className="text-base mb-3">
      “Personal data” and “sensitive personal data” are within the GDPR. “Personal data” is a complex category of information, meaning, broadly, information which can be used to identify a person, for this example, his name, address or IP address. “Sensitive personal information” includes, for example genetic data, and information about religious and political views and sexual orientation. Pseudonymised personal data is now included.            </div>
      <div className="text-base mb-3">
      It is the duty of data controllers and processors to implement technical and organisational measures proportionate to the threats to the data, and the risks involved in the nature, scope and context of processing. The costs of protection must be balanced against the potential damage to data, and the penalties regulators might impose for beaches of the law. Controllers and processors must be aware of the importance of pseudonymisation and the encryption of data. They must maintain: (1) systems to protect the integrity availability and integrity of processing procedures and service; and (2) mechanisms which ensure the regular testing and evaluation of these systems and their efficiency.            </div>
      </> 
    )
    const searchableEncryption = (
        <>
        <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Searchable Encryption
      </div>
      <div className="text-base mb-3">
      Our platform provides for encrypting companies’ databases and makes the encrypted data searchable using Homomorphic and AES encryption schemes. The data is displayed on the user interface. The plain data is not accessible by third parties because data is encrypted before it is sent to the cloud or local database. While encrypted, it is searchable and can be computed or processed. The encrypted result is sent back to the user, and is decrypted before its corresponding plain data is displayed on the user interface. The plain data is protected from hackers and other outsiders.
      </div>
      </> 
    )

    const nextGenDocEncryption = (
        <>
        <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Next Generation Document Encryption & Secure Cloud Archive
      </div>
      <div className="text-base mb-3">
      Testenium uses a next generation AI and meta computing based encryption system to encrypt files and sensitive documents without using the encryption keys external to the software. Testenium creates encryption and decryption software for every user with unique encryption key embedded so that the user does not need to maintain the key, avoiding risk of losing the keys.      </div>
      <div className="text-base mb-3">
      Testenium cloud plaform provides it's users seamless experience of this feature to protect their documents easily.
      </div>
            </> 
    )

    return(
        <Modal
            visible={props.visible}
            onCancel={()=>props.handleCancel()}
            footer={null}
            width={640}
        >
            {props.more === "whyEncryption" && whyEncryption}
            {props.more === "searchableEncryption" && searchableEncryption}
            {props.more === "nextGenDocEncryption" && nextGenDocEncryption}
        </Modal>
    )
}

export default EncryptionMoreModal;