import React, { useState } from "react";
import ami from "./../../assets/images/AMI.png";
import FeatherIcon from "feather-icons-react";
import TestingMoreModal from "../Modals/TestingMoreModal";

function CrossBrowserTestAutomation(){
    const [more,setMore] = useState(false)
    const [moreOf, setMoreOf] = useState("crossBrowserTestAutomation")

    return(
        <>
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3">
        <div className="container">
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl pri-font text-pri-900 mb-3">
              Cross Browser Test Automation
              </div>
              <div className="text-sm sm:text-base md:text-lg mb-6 max-w-5xl mx-auto">
              Testenium is the first and only Meta-computing based QAOps/testing platform in the world that provides online infrastructure to create test project, generate code and...
              </div>
              
                
                  <div
                    onClick={() => {
                      setMore(true);
                      setMoreOf("crossBrowserTestAutomation");
                    }}
                    className="flex items-center text-base  text-sec-500 cursor-pointer hover:text-pri-500" style={{width:"fit-content"}}
                  >
                    Learn more
                    <FeatherIcon icon="arrow-right" className="w-4 h-4 ml-1" />
                  </div>
                
              
            </div>
            <div className="hidden md:block flex-1">
              <div className=" overflow-hidden mx-auto max-w-md ">
                <img className="" src={ami} alt="Web app development" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TestingMoreModal
      visible={more}
      handleCancel={()=>setMore(false)}
      more={moreOf}
      />              
      </>
    )

}
export default CrossBrowserTestAutomation;