import React from "react";
import AutomationAnalyst from "../../components/web/AutomationAnalyst";
import Footer from "../../components/web/Footer";

function AutomationAnalystCert(){
    return(
        <div>
            <div className="h-full">
      <AutomationAnalyst/>
        <Footer/>
        </div>
        </div>
    )
    
}
export default AutomationAnalystCert