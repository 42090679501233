import React from "react";
import web from "./../../assets/images/a.png";
import ui from "./../../assets/images/c.png";
import mobile from "./../../assets/images/b.png";

function Services() {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3" id="services">
      <div className="container">
        <div className="md:flex ">
          <div className="flex-1 p-2">
            <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
              <img
                className="hidden md:block h-20 mx-auto mb-2"
                src={web}
                alt="Web app development"
              />
              <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Automate at scale on Cloud
              </div>
              <div className="text-sm lg:text-base text-center text-pri-500">
                Autoscaling in the cloud can ensure organizations always have
                resources
              </div>
            </div>
          </div>
          <div className="flex-1 p-2">
            <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
              <img
                className="hidden md:block h-20 mx-auto mb-2"
                src={mobile}
                alt="Web app development"
              />
              <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Speech to Code Generation
              </div>
              <div className="text-sm lg:text-base text-center text-pri-500">
                Transforms any information into a data code, which holds the
                entire content
              </div>
            </div>
          </div>
          <div className="flex-1 p-2">
            <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
              <img
                className="hidden md:block h-20 mx-auto mb-2"
                src={ui}
                alt="Web app development"
              />
              <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Next Generation Encryption
              </div>
              <div className="text-sm lg:text-base text-center text-pri-500">
                Provides users seamless experience to protect their documents
                easily
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
