import {  Modal } from "antd";
import React from "react";

function FeaturesMoreModal(props) {
  const one = (
    <>
      <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Voice Activated BDD
      </div>
      <div className="text-base mb-3">
        Generate the feature file by Speech to Code technique. Test the UI of
        the applications without installing any testing tools or writing any
        code. You just need a browser on your device. Prepare a BDD feature file
        with the URL and the elements' details such as locator, function using
        Gherkin language by talking to your computer. Testenium generates 100%
        code, implements all the methods automatically, executes and produces
        reports including video, screenshots and pdf.
      </div>
      <div className="text-base mb-3">
        Testenium is the only cloud platform that provides it's users seamless
        end to end execution without installing tools, preparing a project,
        adding dependencies or implementing the methods for cucumber projects.
      </div>
      <div className="text-base">
        Users can create as many feature files as needed for a project as there is no
        limitation.
      </div>
    </>
  );

  const two = (
    <>
      <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Selenium UI Test Automation without Tools
      </div>
      <div className="text-base mb-3">
        Test the UI of the application without installing any testing tools.
        Prepare an Excel file with elements' details such as locator, function
        and test data for every page in separate excel sheet. Provide the URL on
        the Testenium UI and upload the Excel file. Testenium generates code,
        executes and produces reports including video, screenshots and pdf.
      </div>
      <div className="text-base">
        Testenium is the only cloud platform that provides it's users seamless
        end to end execution without installing tools, preparing a project,
        adding dependencies or wrting code.
      </div>
    </>
  );

  const three = (
    <>
      <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Automatic Method Implementation with BDD
      </div>
      <div className="text-base mb-3">
        Test the UI of the applications without installing any testing tools or
        Excel. You just need a browser on your device. Prepare a BDD feature
        file with the URL and the elements' details such as locator, function
        using Gherkin language. Testenium generates code, implements all the
        methods automatically, executes and produces reports including video,
        screenshots and pdf.
      </div>
      <div className="text-base mb-3">
        Testenium is the only cloud platform that provides it's users seamless
        end to end execution without installing tools, preparing a project,
        adding dependencies or implementing the methods for cucumber projects.
      </div>
      <div className="text-base">
        Users can create as many feature files as needed for a project as there is no
        limitation.
      </div>
    </>
  );

  const four = (
    <>
      <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Encryption without Key-Management
      </div>
      <div className="text-base mb-3">
        Secure your sensitive documents easily with our next generation document
        encryption system.
      </div>
      <div className="text-base">
        You do not have to maintain the keys at all as the unique keys are
        embedded in the software already for every user.
      </div>
    </>
  );

  const five = (
    <>
      <div className="text-xl sm:text-xl md:text-2xl lg:text-2xl pri-font text-pri-900 mb-3">
        Secure Encryption & Cloud Storage
      </div>
      <div className="text-base mb-3">
        Users can also upload any sensitive documents to Testenium cloud
        platform to encrypt or decrypt them very easily. Up on uploading the
        documents, Testenium encrypts the document and allows the user to
        download it or keep it in the cloud so that it can be downloaded any
        time in the future.
      </div>
      <div className="text-base">
        Alternatively, the users are able to encrypt the documents and files
        locally using the feature explained above and then the encrypted files
        can be uploaded for the purpose of storing on the cloud securely.
      </div>
    </>
  );

  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.handleCancel()}
      footer={null}
      width={640}
    >
      {props.more === "1" && one}
      {props.more === "2" && two}
      {props.more === "3" && three}
      {props.more === "4" && four}
      {props.more === "5" && five}
    </Modal>
  );
}

export default FeaturesMoreModal;
