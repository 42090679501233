import React from "react";
import mobile from "./../../assets/images/b.png";
import unlimitedProject from "./../../assets/images/unlimited-project.png";
import unlimitedScreenshots from "./../../assets/images/unlimited-screenshots.png";

function TestingServices(){
    return(
        <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3" >
        <div className="container">
          <div className="md:flex ">
            <div className="flex-1 p-2">
              <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
                <img
                  className="hidden md:block h-20 mx-auto mb-2"
                  src={mobile}
                  alt="Web app development"
                />
                <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Speech to Gherkin Code
                </div>
                
              </div>
            </div>
            <div className="flex-1 p-2">
              <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
                <img
                  className="hidden md:block h-20 mx-auto mb-2"
                  src={unlimitedProject}
                  alt="Web app development"
                />
                <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Unlimited Projects
                </div>
                
              </div>
            </div>
            <div className="flex-1 p-2">
              <div className="border-2 border-pri-500 p-3 lg:p-5 rounded-md h-full">
                <img
                  className="hidden md:block h-20 mx-auto mb-2"
                  src={unlimitedScreenshots}
                  alt="Web app development"
                />
                <div className="text-2xl lg:text-2xl text-center pri-font mb-2 text-pri-500">
                Unlimited Screenshots
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default TestingServices;