import { Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";

function PrivacyPolicyAndCookies(props) {
    return (
      <Modal
        visible={props.show}
        onCancel={() => props.handleClose()}
        footer={null}
        width={1000}
      >

<div className="sm:px-3 md:px-5 lg:px-8 text-sm">
        <div className="text-4xl md:text-5xl pri-font text-center mb-3">
        Privacy Policy & Cookies
        </div>
        <p className="mb-3 text-justify ">
        Testenium believes the efficient collection, use and transfer of information must be handled
in a lawful, fair and responsible manner. This policy sets out openly the ways in which we
may collect and process personal data relating when you do business with us.

        </p>
        <p className="mb-4 text-justify ">
        The data controller and data protection officer is Dr Arasilango
        </p>
        <h1 className="text-3xl pri-font">Providing and Processing your personal data</h1>
        <p className="mb-3 text-justify ">
        By visiting our website you provide no personal data. However, when you register with us
as a customer you will provide personal data, that is, your name postal and email, address
and telephone number. If you buy our services, you will pay either by PayPal, or by
providing credit cards and bank account details, so that you will be charged on account.
        </p>
        <p className="mb-3 text-justify ">
        We process this data by using it only for the usage of Testenium platform and services. It
will not be used for any other purpose 
        </p>
        <p className="mb-3 text-justify ">
        If you apply for employment with us you will submit an application setting out your date of
birth, education and work history, address, email and postal, and telephone numbers, and at
our request details of referees . If you are employed by us you will provide your P45, and
bank account details for the purpose of paying your salary. 
        </p>
        <p className="mb-4 text-justify ">
        If you thereafter suffer from ill-health, you may be required by the terms of your employment
contract to provide medical details of your condition and the name and address of your
doctor. 
        </p>
        <h1 className="text-3xl pri-font">How long do we keep your data?</h1>
        <p className="mb-3 text-justify ">
        We will keep your personal data for as long as our contractual and/or business relationship
lasts. We may then place your personal data in a secure intermediary database for seven
(7) years after our contractual and or business relationship expires, to be accessed only for
tax or regulatory purposes, or as may be required by law.
        </p>
        <p className="mb-3 text-justify ">
        If you are a prospective customer or employees, but do not enter into any relationship with
us, we will maintain your date in this secure database for two year for legal and regulatory
purposes. with no established contractual and/or business relationship, we will not keep
your data for longer than three and a half (3.5) years after you last contacted us.
        </p>
        <p className="mb-3 text-justify ">
        You shared with us at the latest two (2) years after you shared the information.
        </p>
        <p className="mb-4 text-justify ">
        We may retain information collected by the use of our platform for recording the
frequency and scale of your use for accounting purposes for not more than one year after
collection.
        </p>

        <h1 className="text-3xl pri-font">Sharing your Personal Data</h1>
        <h2 className="text-2xl pri-font">1. With other entities of the group</h2>
        <p className="mb-3 text-justify ">
        Testenium is a global organisation. We expect that we shall soon have distinct legal
entities in other countries. We may share your personal data with them, inside and outside
the EU. if the efficiency of data access and management requires the keeping of data in
different locations. 
        </p>
        <h2 className="text-2xl pri-font">2. Judicial, public and/or governmental authorities</h2>
        <p className="mb-3 text-justify ">
        We may also be required by law, such as by requirements by made public and
governmental authorities, within or outside your country of residence, to disclose your
personal data to them. We shall inform you if we are required to supply such data, unless
we are forbidden by law to do so, 
        </p>
        <p className="mb-4 text-justify ">
        We may also disclose your personal information if we determine in good faith that
disclosure is reasonably necessary to protect our rights and to pursue available remedies,
enforce our terms and conditions, investigate fraud, or protect our operations or users from
unlawful attack.
        </p>

        <h1 className="text-3xl pri-font">Business reorganisations</h1>
        <p className="mb-4 text-justify ">
        Like many organisations, Testenium may reorganise its business operations around the
world from time to time, whether by buying new businesses or selling or merging existing
businesses. This may involve disclosure by us of personal data to prospective or actual
purchasers of parts of our business, or receiving personal data from potential sellers. It is
our practice to require appropriate confidentiality protection for personal data disclosed in
these types of transactions.
        </p>
        <h1 className="text-3xl pri-font">Your rights</h1>
        <p className="mb-3 text-justify ">
        In any case, you have the right to access your personal data.
        </p>
        <p className="mb-3 text-justify ">
        You can also request that your personal data be corrected. You are entitled to task us to
provide you with a copy of your data in an accessible format.
        </p>
        <p className="mb-3 text-justify ">
        In case of any such request or complaint, please send an email to <Link className="inline-block text-sec-500 hover:text-sec-500" to={{ pathname: "mailto:info@Testenium.com" }} target="_blank">info@Testenium.com</Link>.
You can also contact our Data Protection Officer by sending an email to the following
address: <Link className="inline-block text-sec-500 hover:text-sec-500" to={{ pathname: "mailto:info@Testenium.com" }} target="_blank">info@Testenium.com</Link> 
        </p>
        <p className="mb-4 text-justify ">
        In any case, you also have the right to lodge a complaint with the competent data
protection authority. In the UK, for example, this authority is the Information
Commissioner’s Office. 
        </p>

        <h1 className="text-3xl pri-font">Data security and integrity</h1>
        <p className="mb-4 text-justify ">
        Testenium uses various technological and procedural security measures in order to protect
the personal data we collect, use or transfer against loss, misuse, alteration or destruction.
While your date is held within our platform, you have complete protection from unlawful
access by hackers because use the required encryption and decryption technology, that is,
Searchable Encryption (homomorphic)which is one of our main products. 
        </p>
        <h1 className="text-3xl pri-font">Third-party websites</h1>
        <p className="mb-4 text-justify ">
        The two Testenium websites may provide links to third-party websites. Testenium does
not make any representations or warranties with respect to the policies and practices of
such third-party websites in relation to date protection. . You should be aware that the
owners and operators of such third-party websites might collect, use or transfer personal
data under different terms and conditions than Testenium. Upon linking to a third-party
website, you should inform yourself of its privacy policy.
        </p>
        <h1 className="text-3xl pri-font">Notification of changes to this Policy</h1>
        <p className="mb-4 text-justify ">
        This policy was last updated on 26 November 2019. We may periodically change it to
keep pace with new technologies, industry practices, and regulatory requirements, among
other reasons. We expect most such changes to be minor. When we will need to make
material change to our privacy policy, we will post a prominent notice on the home page
of the website before the change becomes effective.
        </p>
        <h1 className="text-3xl pri-font">Cookies</h1>
        <p className="mb-3 text-justify ">
        Cookies are used on this website. By using our website, you agree that cookies are to be
placed on your device as further explained below.
        </p>
        <p className="mb-3 text-justify ">
        Cookies are small pieces of data that a website sends to your computer’s web browser
while you are navigating through it. They have many different purposes, but mainly,
cookies enable you to navigate between pages easily, to remember your preferences, and
eventually to improve the user experience. These cookies may then be stored on your
machine to identify your computer.
        </p>
        <p className="mb-3 text-justify ">
        Cookies used on this website can be either set up by our website or by a third-party
website. Moreover, these cookies can be “session” or “persistent” cookies: a session
cookie is a cookie that is automatically deleted when the user closes the browser, whereas
a persistent cookie is a cookie that remains stored in the user’s terminal device until it
reaches a defined expiration date. Cookies used on Testenium website have a maximum
lifetime of 12 months.
        </p>
        <h2 className="text-2xl pri-font">A. Types of cookies we use</h2>
        <p className="mb-3 text-justify ">
        We use several types of cookies on this website as described below.
        </p>
        <h3 className="text-xl pri-font">Strictly necessary cookies</h3>
        <p className="mb-2 text-justify ">
        These cookies are essential to enable you to move around the website and use its features,
such as accessing secure areas of the website. Without these cookies, services you have
asked for cannot be provided.
Strictly necessary cookies used on the Testenium website:
        </p>
        <ul className="mb-3 list-disc list-outside ml-8">
          <li>
            <p>Registered visitor cookies.</p>
          </li>
        </ul>
        <h3 className="text-xl pri-font">Functionality cookies</h3>
        <p className="mb-2 text-justify ">
        These cookies allow a website to remember choices you make and provide enhanced, more
personal features. For instance, a website may be able to provide you with local
information or news by storing in a cookie the region in which you are currently located.
These cookies can also be used to remember changes you have made to the text size, font
and other parts of the web pages that you can customise. They may also be used to provide
services you have asked for such as watching a video or commenting on a blog. These
cookies cannot track your browsing activity on other websites. They do not gather any
information that could be used for advertising. Functionality cookies used on the
Testenium website:
        </p>
        <ul className="mb-3 list-disc list-outside ml-8">
          <li>
            <p className="mb-2 text-justify ">Registered visitor functionality cookies.</p>
          </li>
          <li>
            <p className="text-justify ">Social plug-in content sharing cookies.</p>
          </li>
        </ul>
        <h3 className="text-xl pri-font">Performance cookies</h3>
        <p className="mb-3 text-justify ">
        These cookies are used to collect information about how visitors use a website, for
instance which pages they go to more often, and if they get error messages from web
pages. All information collected by means of these cookies is anonymised, aggregated and
only used to develop and track traffic patterns and the volume of use of our website and to
improve how our website works. This information is for Testenium’s exclusive use and is
not shared with any third party or connected to any other information.

        </p>
        <p className="mb-2 text-justify ">
        Performance cookies used on the Testenium website:
        </p>
        <ul className="mb-3 list-disc list-outside ml-8">
          <li>
            <p className="mb-2 text-justify ">Unregistered visitor cookies.</p>
          </li>
          <li>
            <p>Analytic cookies.</p>
          </li>
        </ul>
        <h2 className="text-2xl pri-font">B. Use of cookies</h2>
        <p className="mb-3 text-justify ">
        We may use personal data collected from our cookies to identify user behaviour and to
serve content and offers based on your profile.
        </p>
        <p className="mb-3 text-justify ">
        The performance cookies used on this website do not collect personal data.
        </p>
        <p className="mb-2 text-justify ">
        Other cookies can collect personal data (including information from cookies placed via our
advertisements on third-party websites):
        </p>
        <ul className="mb-3 list-disc list-outside ml-8">
          <li>
            <p className="mb-2 text-justify ">If a user is a registered user.</p>
          </li>
          <li >
            <p className="mb-2 text-justify ">If we send you a targeted email which includes web beacons, cookies or similar
technologies we will know whether you open, read, or delete the message.</p>
          </li>
          <li>
            <p className="text-justify ">When you click a link in a marketing e-mail you receive from Testenium, we will also
use a cookie to log what pages you view and what content you download from our
websites, even if you are not registered at or signed into our site.</p>
          </li>
        </ul>
        </div>
        </Modal>
    )
}
export default PrivacyPolicyAndCookies;