import React from "react";
import AutomationPlatformDev from "../../components/web/AutomationPlatformDev";
import Footer from "../../components/web/Footer";



function AutomationPlatformDevCert(){
    return(
        <div>
            <div className="h-full">
       <AutomationPlatformDev/>
        <Footer/>
        </div>
        </div>
    )
    
}

export default AutomationPlatformDevCert
