import React from "react";
import DevOpsEngineer from "../../components/web/DevOpsEngineer";
import Footer from "../../components/web/Footer";



function DevOpsEngineerCert(){
    return(
        <div>
            <div className="h-full">
       <DevOpsEngineer/>
        <Footer/>
        </div>
        </div>
    )
    
}

export default DevOpsEngineerCert