import React from "react";
import medal from "./../../assets/images/ceo_views.png"

function AboutUsHero(){
    return(
        <div  className="w-full h-full pt-40 pb-10 bg-pri-500 ">
      <div className="container flex items-center justify-center gap-4 lg:justify-between h-full px-3">
        <div className="text-center  md:text-left text-white">
          <div className="text-center lg:text-left pri-font hero-text tracking-wide leading-tight max-w-2xl mb-5">
            Top company of the year 2020
          </div>
          <div className="text-center lg:text-left sm:text-lg md:text-xl lg:text-xl  max-w-xl">
          Unbeatable one-stop Test Automation Cloud platform for code generation, execution and test management.
                Millions of lines of code are generated every second.
          </div>
          
        </div>
        <div className="hidden lg:block  lg:max-w-xs  overflow-hidden ">
          <img src={medal} alt="testenium"/>
        </div>
      </div>
      
    </div>
    )
}
export default AboutUsHero;