import React from "react";
import AutomationArchitect from "../../components/web/AutomationArchitect";
import Footer from "../../components/web/Footer";



function AutomationArchitectCert(){
    return(
        <div>
            <div className="h-full">
       <AutomationArchitect/>
        <Footer/>
        </div>
        </div>
    )
    
}

export default AutomationArchitectCert
