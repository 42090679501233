import React, { useState } from "react";
import { Link } from "react-router-dom";

function AutomationEngineer(){
    const [menuItem, setMenuItem] = useState("objectives")
    

    const objectives = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Prove your skills and knowledge
            </div>
            <div className="text-sm sm:text-base  text-justify ">
            Testenium Test Automation Engineer must have expertise in setting up test environment and creating test project in local computer and online, writing test script in C# or java programming languages, creating Test Design Frameworks and using Testenium Online Test Automation Platform for AMPT and generating test scripts.
            </div>
            
        </>
    )
    const audience = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Is this certification right for you?
            </div>
            <div className="text-sm sm:text-base  text-justify ">
            Testenium Test Automation Engineer must have expertise in setting up test environment and creating test project in local computer and online, writing test script in C# or java programming languages, creating Test Design Frameworks and using Testenium Online Test Automation Platform for AMPT and generating test scripts.
            </div>
            
                    </>
    )
    const contents = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        The best way to learn is to do
            </div>
            <div className="text-sm sm:text-base  text-justify">
            Testenium Test Automation Engineer must have expertise in setting up test environment and creating test project in local computer and online, writing test script in C# or java programming languages, creating Test Design Frameworks and using Testenium Online Test Automation Platform for AMPT and generating test scripts.
            </div>
            {/* <ul className="text-sm sm:text-base  text-justify list-disc list-outside ml-5">
                <li>No data</li>
                
                
            </ul> */}
            </>
    )
    const exam = (
        <>
        <div className="md:text-lg sm:text-xl font-semibold mb-2">
        Test your skills and knowledge
            </div>
            <div className="text-sm sm:text-base  text-justify ">
            Testenium Test Automation Engineer must have expertise in setting up test environment and creating test project in local computer and online, writing test script in C# or java programming languages, creating Test Design Frameworks and using Testenium Online Test Automation Platform for AMPT and generating test scripts.
            </div>
            </>
    )

    return(
        <>
        <div id="top" className="w-full h-full pt-24 px-3 bg-pri-500">
      <div className="container flex items-center   h-full ">
          <div className="  text-white mt-6 py-10">
          <div className="text-lg sm:text-2xl md:text-2xl lg:text-3xl  pri-font text-white">
            <Link className="text-white hover:text-sec-500 cursor-pointer" to="/training">Training</Link> / Certification
          </div>
              <div className="pri-font hero-text tracking-wide leading-tight max-w-5xl ">
              Test Automation Engineer
              </div>
          </div>
      </div>
  </div>
  <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-white">
            <div className="container">
            <div className="text-lg sm:text-xl md:text-2xl  pri-font  mb-2">
                Overview
                </div> 
                <div className="text-sm sm:text-base md:text-lg text-justify">
                Testenium Test Automation Engineer must have expertise in setting up test environment and creating test project in local computer and online, writing test script in C# or java programming languages, creating Test Design Frameworks and using Testenium Online Test Automation Platform for AMPT and generating test scripts.
                </div>           
              </div>
    </div>

    <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3 bg-gray-200">
            <div className="container">
            <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center pri-font mb-6">
            Certification details
                </div>
           
                 <div>
                    <div className="flex">
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "objectives" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("objectives")
                        
                            }}
                        >Objectives</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "audience" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("audience")
                        
                            }}
                        >Audience</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "contents" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("contents")
                        
                            }}
                        >Contents</div>
                    <div className={"text-lg sm:text-xl md:text-2xl cursor-pointer pri-font p-2 md:p-6 rounded-tl-md rounded-tr-md mt-4 hover:bg-white " 
                        + (menuItem === "exam" && " bg-white ")}
                            onClick={() => {
                                setMenuItem("exam")
                        
                            }}
                        >Exam</div>

                    </div>
                <div className=" bg-white p-2 md:p-6 rounded-bl-md rounded-br-md">
                    {menuItem === "objectives" && objectives}
                    {menuItem === "audience" && audience}
                    {menuItem === "contents" && contents}
                    {menuItem === "exam" && exam}
                </div>
                </div>
            </div>
    </div>
    
  </>
    )
}

export default AutomationEngineer