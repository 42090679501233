import React from "react";

import Footer from "../components/web/Footer";
import TrainingCourses from "../components/web/TrainingCourses";
import TrainingHero from "../components/web/TrainingHero";

function Training() {
  return (
    <div>
      <div className="h-full">
        <TrainingHero />
        <TrainingCourses />
        <Footer />
      </div>
    </div>
  );
}

export default Training;
