import React from "react";
import Slider from "react-slick";

import video1 from "../../assets/videos/cropped_5.mp4";
import video2 from "../../assets/videos/cropped_6.mp4";
import video3 from "../../assets/videos/cropped_8.mp4";
import HeroScrollBtn from "../UI/HeroScrollBtn";

function Hero3() {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id="top" className="hero3 w-full h-full overflow-hidden pt-24">
      <Slider {...settings}>
        <div className="relative">
          <div className="absolute hero3-text  z-10 top-0 bottom-0  flex justify-center  items-center h-full w-full px-3">
            <div className=" text-center md:text-left text-white">
              <div className="text-center pri-font hero-text tracking-wide leading-tight max-w-5xl mb-3 md:mb-5 ">
                Automate the Automation
              </div>
              <div className="text-center  sm:text-lg md:text-xl lg:text-2xl  max-w-3xl ">
                Testenium generates code for test automation, encryption, Big
                Data Analytics and Blockchain testing.
              </div>
            </div>
          </div>
          <div className="video--wrapper">
            <video autoPlay muted loop>
              <source src={video1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="relative">
          <div className="absolute hero3-text  z-10 top-0 bottom-0  flex justify-center  items-center h-full w-full px-3">
            <div className=" text-center md:text-left text-white">
              <div className="text-center pri-font hero-text tracking-wide leading-tight max-w-5xl mb-3 md:mb-5 ">
                Next Generation Meta-automation Platform
              </div>
              <div className="text-center  sm:text-lg md:text-xl lg:text-2xl  max-w-3xl mx-auto">
                Automate any task faster and get reports within hours using
                Testenium Meta-automation platform
              </div>
            </div>
          </div>
          <div className="video--wrapper">
            <video autoPlay muted loop>
              <source src={video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="relative">
          <div className="absolute hero3-text  z-10 top-0 bottom-0  flex justify-center  items-center h-full w-full px-3">
            <div className=" text-center md:text-left text-white">
              <div className="text-center pri-font hero-text tracking-wide leading-tight max-w-5xl mb-3 md:mb-5 ">
                Automation Platform to Accelerate your Business
              </div>
              <div className="text-center  sm:text-lg md:text-xl lg:text-2xl  max-w-3xl mx-auto">
                Testenium is the fastest meta-automation platform that supports
                software testing, RPA, Big Data Analytics and Encryption
              </div>
            </div>
          </div>
          <div className="video--wrapper">
            <video autoPlay muted loop>
              <source src={video3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Slider>

      <div className="absolute w-full bottom-0">
        <div className="text-center p-4">
          <HeroScrollBtn />
        </div>
      </div>
    </div>
  );
}

export default Hero3;
